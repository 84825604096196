import React, { useCallback } from "react";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { instance } from '../../API/axios';
import debounce from "lodash.debounce";
import { useHistory } from 'react-router-dom';
import DialogContent from '@material-ui/core/DialogContent';
import { getUser } from '../../Router/auth';

export default function Asynchronous(props) {
  const {
    meta: { submitting, error, touched, invalid },
    input: { onBlur, value, ...inputProps },
    required,
    validate,
    ref,
    label,
    endpoint,
    onChang2,
    lookup_parametro,
    ...others
  } = props;

  const [open, setOpen] = React.useState(false);
  const [openDialogNew, setOpenDialogNew] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [value2, setValue2] = React.useState({ id: -1, descricao: '' });
  const [load, setLoad] = React.useState(false);
  const [filter, setFilter] = React.useState("");
  const [filtroExtra, setFiltroExtra] = React.useState("");
  const [valueInicial, setValueInicial] = React.useState(-1);
  const history = useHistory();

  async function getAll(filtro = "", id = -1) {
    try {
      
      var filtroToLink = '';

      if (value.lookup_parametro) {
        setFiltroExtra(value.lookup_parametro);
        filtroToLink += value.lookup_parametro;
      }
      if (filtroExtra && filtroToLink === '')
        filtroToLink += filtroExtra;

      if (id !== -1) {
        filtroToLink += (filtroToLink !== '' ? "&id=" + id.toString() : "id=" + id.toString());
      }
      if (filtro !== "") {
        filtroToLink += (filtroToLink !== '' ? "&filtro=" + filtro : "filtro=" + filtro);
      }

      var response = await instance.get(endpoint + "?maxRegistro=50" + (filtroToLink !== '' ? "&" + filtroToLink : ''));
      
      if (response.status === 200) {
        return response.data.registros;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function updateLista(filtro = "") {
    setLoad(true);

    var lista = await getAll(filtro);
    setOptions(lista);

    setLoad(false);
  }

  React.useEffect(() => {
    let active = true;

    if (!load) {
      return undefined;
    }

    (async () => {
      if (active && filter === "") {
        await updateLista();
      }
    })();

    return () => {
      active = false;
    };
  }, [load]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(async () => {

    var empresaId = getUser().empresaId;
    var seguirSugestao = false;
    if (empresaId === 69) // Panorama => Empresas que devem sempre seguir a sugestão
      seguirSugestao = true;

    if (value2.id === -1 || (value2.id != valueInicial && seguirSugestao)) {
      setLoad(true);
      var l = await getAll("", valueInicial);
      if (l.length > 0 && valueInicial > -1) {
        setValue2(l[0]);
        inputProps.onChange(l[0].id)
      }
      setLoad(false);
    }

  }, [valueInicial]);

  React.useEffect(async () => {
    if (typeof value === 'object') {
      setValue2({ id: -1, descricao: '' });

      setValueInicial(value.value.id)
    }
    else
      setValueInicial(value)

  }, [value]);

  React.useEffect(async () => {
    if (value2.id > -1 && value2.descricao == '') {
      setLoad(true);
      var l = await getAll("", value2.id);
      if (l.length > 0) {
        setValue2(l[0]);
        inputProps.onChange(l[0].id)
      }
      setLoad(false);
    }

  }, [value2]);

  const changeHandler = (event) => {
    setFilter(event.target.value);
    updateLista(event.target.value);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

  const handleCloseDialogNew = () => {
    setOpenDialogNew(false);
  };

  return (
    <>
      <Autocomplete
        {...inputProps}
        {...others}
        sx={{ width: 300 }}
        open={open}
        onOpen={() => {
          setLoad(true);
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.descricao}
        getOptionSelected={(option, value) => {
          return option.id === value.id
        }}
        options={options}

        required={required}


        loading={load}
        loadingText='Carregando...'
        error={touched && invalid}
        helperText={touched && error}
        value={value2}
        noOptionsText='Nenhum registro encontrado'

        onChange={(v, b) => {
          if (b != null) {
            setValue2(b);
            inputProps.onChange(b.id);
            onChang2(b.id);
            setFilter("")
          } else {
            setValue2({ id: -1, descricao: '' });
            onChang2(-1);
            inputProps.onChange(-1)
          }
        }}
        ref={ref}

        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            required={required}
            onChange={debouncedChangeHandler}
            helperText={touched && error}
            error={touched && invalid}
            InputProps={{
              ...params.InputProps,
              //value:"sd",
              endAdornment: (
                <React.Fragment>
                  {load ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                  {props.linkToCriar ?
                    <>
                      <Tooltip title="Novo registro">

                        <IconButton size="medium" style={{ padding: '7px' }} onClick={() => { setOpenDialogNew(true); }}>
                          <AddBoxIcon />
                        </IconButton>
                      </Tooltip>
                    </> : <></>}
                  {props.podeAlterar ?
                    <Tooltip title="Editar registro">

                      <IconButton size="medium" style={{ padding: '7px' }} >
                        <EditIcon />
                      </IconButton>
                    </Tooltip> : <></>}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {props.linkToCriar ?
        <Dialog fullWidth open={openDialogNew} onClose={handleCloseDialogNew} maxWidth={"xl"} disableEnforceFocus={false}>
          <DialogContent>

            {React.createElement(props.componentToCriar, { 
              returnId: (id) => 
              { 
                setValue2({ id: id, descricao: '' }); 
                inputProps.onChange(id); 
                onChang2(id);
                setFilter(""); 
                setOpenDialogNew(false) 
              } 
            })}

          </DialogContent>
        </Dialog>
        : <></>}
    </>
  );
}
