import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PaymentIcon from '@material-ui/icons/AttachMoney';
import LanguageIcon from '@material-ui/icons/Language';
import CarIcon from '@material-ui/icons/LocalShipping';
import PinDropIcon from '@material-ui/icons/Map';
import InstallMobileIcon from '@material-ui/icons/MobileFriendly';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ClienteIcon from '@material-ui/icons/PersonPinCircleSharp';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import { useHistory } from 'react-router-dom';
import { PodeAcessarRelatorios, getUser } from '../../Router/auth';
import { ReactComponent as localPlanejado } from '../../img/icon/localPlanejado_4.svg';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

export function MyCard(props) {
	const history = useHistory();
	const classes = useStyles();
	const [styleCard, setstyleCard] = React.useState({});

	function onMouseEnter(e) { setstyleCard({ background: "#ede9e9" }) }
	function onMouseLeave(e) { setstyleCard({}) }

	return (
		<Card
			className={classes.paper}
			onClick={() => { history.push(props.path); }}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			style={styleCard}
		>
			<Cardd card={props}></Cardd>
		</Card>
	);

	function Cardd(props) {
		return <Grid container
			direction="column"
			justifyContent="center"
			alignItems="center"
		>
			<Grid item>{props.card.icon}</Grid>
			<Grid item><Typography variant="h6" style={{ fontSize: "1.1rem" }} gutterBottom>{props.card.text}
			</Typography></Grid>
		</Grid>
	}
}

export default function Cadastros() {
  const classes = useStyles();
  
  const tamanhoIcone = 70;
  const styleicon = { fontSize: tamanhoIcone };
  const lista = [
    {text: "Clientes", icon: <ClienteIcon style={styleicon}/>, path:"/clientes", roles:["Master", "RLuOKTh1Ko"]},
    {text: "Pagamentos", icon: <PaymentIcon style={styleicon}/>, path:"/pagamentos", roles:["Master", "RLuOKTh1Ko"]},
    //{text: "Entregas", icon: <SvgIcon component={entrega} style={styleicon}/>, path:"/entregas"},
    {text: "Usuários", icon: <PeopleAltIcon style={styleicon}/>, path:"/usuarios", roles:["Master", "RLuOKTh1Ko"]},
    {text: "Veículos", icon: <CarIcon style={styleicon}/>, path:"/veiculos", roles:["Master", "RLuOKTh1Ko"]},
    {text: "Locais", icon: <SvgIcon component={localPlanejado} style={styleicon}/>, path:"/locais", roles:["Master", "RLuOKTh1Ko"]},
    {text: "Regiões", icon: <LanguageIcon style={styleicon}/>, path:"/regioes", roles:["Master", "RLuOKTh1Ko"]},
    {text: "APP do motorista", icon: <InstallMobileIcon style={styleicon}/>, path:"/aplicativo"},
    {text: "Mapa", icon: <PinDropIcon style={styleicon}/>, path:"/mapa", roles:["RLuOKTh1Ko"]},
	  {text: "Download de Evidências", icon: <PhotoCameraOutlinedIcon  style={styleicon}/>, path:"/pacotesEvidencias", roles:["Master","RLuOKTh1Ko"]}
  ];

  if (PodeAcessarRelatorios()) {
    lista.push({text: "Relatórios", icon: <AssignmentIcon style={styleicon}/>, path:"/relatorios"});
  }

	function FormRow() {
		var tamanhoXs = 2;
		var element = [];

		lista.forEach(e => {
			if (e.roles == undefined || (e.roles != undefined && e.roles.indexOf(getUser().role) != -1)) {
				element.push(<Grid item xs={12} sm={6} md={4} lg={tamanhoXs} xl={tamanhoXs}>
					<MyCard {...e}></MyCard>
				</Grid>)
			}
		});

		return (
			<React.Fragment>
				{element}
			</React.Fragment>
		);
	}

	return (
		<div className={classes.root}>
			<Grid container spacing={1}>
				<Grid container item xs={12} spacing={3}>
					<FormRow />
				</Grid>
			</Grid>
		</div>
	);
}
