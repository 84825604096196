import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TextField, CircularProgress, Container, Typography, Box, Grid, Snackbar } from '@mui/material';
import Button from '../../Components/Button/Button';
import { enviarXmlPorEmail } from '../../API/NotasFiscais';
import { getPessoaByParadaPlabejadaId } from '../../API/Pessoas';

var jwt = require('jsonwebtoken');

const EnviarXmlPorEmail = () => {
	const location = useLocation();

	const searchParams = useMemo(() => {
		return new URLSearchParams(location.search);
	}, [location.search]);

	const params = useMemo(() => {
		const paramsObj = {};
		for (const [key, value] of searchParams.entries()) {
			paramsObj[key] = value;
		}
		return paramsObj;
	}, [searchParams]);

	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [slackOpen, setSlackOpen] = useState(false);
	const [slackText, setSlackText] = useState("");

	useEffect(() => {
		getPessoaByParadaPlabejadaId(params.paradaPlanejadaId)
			.then(resp => {
				if (resp !== null) {
					setEmail(resp.email);
				}
			})
	}, [params]);

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};

	const handleSubmit = () => {
		setLoading(true);

		enviarXmlPorEmail({
			empresaId: jwt.decode(params.token).EmpresaId,
			paradaPlanejadaId: params.paradaPlanejadaId,
			emailDestino: email
		}).then(resp => {
			setSlackText(resp.message);
		}).catch(error => {
			setSlackText(error.message);
		}).finally(_ => {
			setSlackOpen(true);
			setLoading(false);
		});
	};

	const handleCloseSlack = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSlackOpen(false);
	};

	return (
		<Container maxWidth="sm">
			<Box
				display="flex"
				flexDirection="column"
				height="100vh"
				style={{ marginTop: '30px' }}
			>
				<Snackbar
					open={slackOpen}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={5000}
					onClose={handleCloseSlack}
					message={slackText}
				/>

				<Typography variant="h6" align="center">
					Confirme o e-mail para enviar o documento, e clique em "Enviar".
				</Typography>
				<Box mt={3}>
					<TextField
						fullWidth
						label="Email"
						variant="outlined"
						value={email}
						onChange={handleEmailChange}
					/>
				</Box>
				<Grid container spacing={2} style={{ marginTop: '12px' }} >
					<Grid item xs={12} md={12} >
						<Button
							fullWidth
							color="primary"
							style={{ backgroundColor: '#fd7826' }}
							variant="contained"
							onClick={handleSubmit}
							disabled={loading}
						>
							{loading ? <CircularProgress size={24} /> : 'Enviar'}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
};

export default EnviarXmlPorEmail;