import React, { useEffect, useCallback, useState, useRef } from 'react';
import debounce from "lodash.debounce";
import { AtualizarVolume, consultarDocumento } from '../../API/NotasFiscais';
import { getFormatDataToString } from '../../Utils/FormatDateTime';
import { Box, CircularProgress, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import { BtnBusca } from "./components/BtnBusca";
import { useReactToPrint } from 'react-to-print';
import { formatarNumeroParaReais } from '../../Utils/Money';
import { getFormatEndereco_01 } from '../../Utils/Endereco';
import EtiquetaManager from '../../Components/Etiquetas/EtiquetaManager';
import { MensagemRetorno } from './components/MensagemRetorno';
import useAutoFocus from '../../Hooks/useAutoFocus';
import ButtonCustom from '../../Components/Button/Button';
import { EmpresaIds } from '../../Utils/Empresa';
import { getUser } from '../../Router/auth';

function AdicionarNF(props) {
    const [rows, setRows] = useState([]);
    const [busca, setBusca] = useState("");
    const [permitirBusca, setPermitirBusca] = useState(true);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [mostrarInfos, setMostrarInfos] = useState(false);
    const [mostrarErro, setMostrarErro] = useState(false);
    const [consulta, setConsulta] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [doc, setDoc] = useState(null);
    const [open, setOpen] = useState(false);
    const [volumeEtiqueta, setVolumeEtiqueta] = useState(null);
    const [loadingImprimir, setLoadingImprimir] = useState(false);

    const inputBtnBusca = useAutoFocus();

    useEffect(() => {
        try {
            if (consulta.data.length === 1) {
                setMostrarInfos(true);
                setDoc(consulta.data[0]);
                return;
            }

            setMostrarErro(true);
            setMostrarInfos(false);
            return;
        } catch {
            setMostrarInfos(false);
            return;
        } finally {
            setVolumeEtiqueta(null);
        }

    }, [consulta]);

    const contentRef = useRef();
    const reactToPrintFn = useReactToPrint({
        contentRef: contentRef,
        onAfterPrint: () => inputBtnBusca.current.focus()
    });

    useEffect(() => {
        if (open) {
            const timeoutId = setTimeout(() => {
                reactToPrintFn();
                setOpen(false);
            }, 1000);

            return () => clearTimeout(timeoutId);
        }
    }, [open, reactToPrintFn]);

    const consultarNF = (filtro, notaId = null) => {
        setIsButtonDisabled(true);
        setIsLoading(true);

        if (filtro === "") {
            setIsLoading(false);
            setIsButtonDisabled(false);

            inputBtnBusca.current.focus();
            return;
        }

        setRows([]);

        let rotaId = props.rotaId ?? null;

        consultarDocumento(filtro, rotaId, notaId)
            .then((resp) => {
                setConsulta(resp);
                setRows(resp.data);
            }).catch(err => {
                setConsulta(null);
                setRows([]);
            }).finally(f => {
                setIsLoading(false);
                setIsButtonDisabled(false);
                setBusca("");

                inputBtnBusca.current.focus();
            });
    }

    const handleBusca = (e) => {
        setBusca(e.target.value);

        if (e.target.value.length >= 44) {
            setPermitirBusca(false);
            consultarNF(e.target.value)
        }

        debounceHandleBusca(e);
    }

    const debounceHandleBusca = useCallback(debounce((e => {
        if (e.target.value.trim() === "")
            return;

        if (permitirBusca && e.target.value.length < 44) {
            consultarNF(e.target.value);
        }
    }), 1000), []);

    const onKeyDownSearch = (e) => {
        if (e.key === 'Enter') {
            consultarNF(e.target.value);
        }
    }

    const onClickSearch = (chave, notaId = null) => {
        if (rows.length > 1) {
            consultarNF(chave, notaId);
        }
    }

    const handleClickImprimir = () => {
        setLoadingImprimir(true);

        AtualizarVolume(doc.id, {
            quantidadeVolume: volumeEtiqueta
        }).then(resp => {
            if (resp.data != null) {
                setDoc((prevState) => ({
                    ...prevState,
                    codigoEntrega: resp.data.codigoEntrega
                }));
            }
        }).catch(err => {

        }).finally(f => {
            setLoadingImprimir(false);
            setOpen(true);
        });
    }

    return (
        <Grid
            container
            spacing={2}
        >
            <Grid
                item
                lg={6}
                md={6}
                xl={6}
                sm={12}
                xs={12}
            >
                <Typography
                    component="h1"
                    sx={{
                        fontSize: "32px",
                        fontWeight: "bold",
                        padding: "15px 0px"
                    }}
                >
                    Adição rápida de NF
                </Typography>

                <BtnBusca
                    id="btn-busca"
                    label="BUSCAR (CHAVE NF, Nº DA ROTA/PEDIDO)"
                    variant="filled"
                    value={busca}
                    onChange={handleBusca}
                    onKeyDown={onKeyDownSearch}
                    disabled={isButtonDisabled}
                    inputRef={inputBtnBusca}
                />

                {rows.length >= 1 &&
                    <TableContainer
                        component={Paper}
                        sx={{
                            marginTop: "50px",
                        }}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nº da nota/Pedido</TableCell>
                                    <TableCell>Destinatário</TableCell>
                                    <TableCell>Valor total</TableCell>
                                    <TableCell>Data de emissão</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.chave}
                                        onClick={() => {
                                            onClickSearch(row.chave, row.notaFiscalId);
                                        }}
                                        sx={{
                                            ":hover": {
                                                cursor: "pointer",
                                                backgroundColor: "#e0e0e0"
                                            }
                                        }}
                                    >
                                        <TableCell>{row.numeroDocumentoFiscal}</TableCell>
                                        <TableCell>{row.destinatario.razaoSocial} ({row.destinatario.nomeFantasia})</TableCell>
                                        <TableCell>{row.valor}</TableCell>
                                        <TableCell>{getFormatDataToString(row.dataEmissao)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Grid>
            <Grid
                item
                lg={6}
                md={6}
                xl={6}
                sm={12}
                xs={12}
            >
                <>
                    {(mostrarInfos || mostrarErro || isLoading) &&
                        <Typography
                            component="h1"
                            sx={{
                                fontSize: "32px",
                                fontWeight: "bold",
                                padding: "15px 0px"
                            }}
                        >
                            Informações
                        </Typography>
                    }

                    {isLoading &&
                        <Box sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CircularProgress />
                        </Box>
                    }

                    {(mostrarInfos && !isLoading && (getUser().empresaId === EmpresaIds.Krambeck || getUser().empresaId === EmpresaIds.EmpresaDoPedro)) ? (
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item>
                                <TextField
                                    label="Volumes"
                                    type='number'
                                    value={volumeEtiqueta}
                                    onChange={(e) => { setVolumeEtiqueta(e.target.value) }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            setOpen(true);
                                        }
                                    }}
                                    sx={{
                                        width: "200px",
                                        height: "auto"
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <ButtonCustom
                                    onClick={handleClickImprimir}
                                    variant="contained"
                                    loading={loadingImprimir}
                                    sx={{
                                        width: "250px",
                                        height: "100%"
                                    }}
                                >
                                    Imprimir
                                </ButtonCustom>
                            </Grid>
                        </Grid>
                    ) : <></>}

                    {((mostrarErro || mostrarInfos) && !isLoading) &&
                        <MensagemRetorno consulta={consulta} />
                    }

                    {(mostrarInfos && !isLoading) &&
                        <Box>
                            <Modal
                                open={open}
                                onClose={() => { }}
                                sx={{
                                    marginTop: "10000px", // pra nao aparecer na tela
                                }}
                            >
                                <Box
                                    ref={contentRef}
                                    sx={{
                                        position: 'absolute',
                                        height: "100%",
                                        bgcolor: 'background.paper'
                                    }}
                                >
                                    {Array.from({ length: volumeEtiqueta }, (_, i) => i).map((index) => {
                                        return (
                                            <EtiquetaManager
                                                key={index}
                                                documento={doc}
                                                volume={index + 1}
                                                volumeTotal={volumeEtiqueta}
                                            />
                                        )
                                    })}
                                </Box>
                            </Modal>

                            <Typography sx={{ fontSize: "18px" }}>
                                <strong>Rota:</strong> {(doc.rota != null) ? (
                                    <>{doc.rota.descricaoRotaRegiao} - {doc.rota.nomeMotorista} - {doc.rota.placaVeiculo}/{doc.rota.apelidoVeiculo} - {getFormatDataToString(doc.rota.data)}</>
                                ) : <></>}
                            </Typography>

                            <Typography sx={{ fontSize: "18px" }}>
                                <strong>Nº da nota/Pedido:</strong> {doc.numeroDocumentoFiscal}
                            </Typography>

                            <br />

                            <Typography sx={{ fontSize: "18px" }}>
                                <strong>Destinatário:</strong> {doc.destinatario.razaoSocial} ({doc.destinatario.nomeFantasia})
                            </Typography>

                            <Typography sx={{ fontSize: "18px" }}><strong>Valor total da nota:</strong> {formatarNumeroParaReais(doc.valor)}</Typography>
                            <Typography sx={{ fontSize: "18px" }}><strong>Data de emissão da nota:</strong> {getFormatDataToString(doc.dataEmissao)}</Typography>
                            <br />
                            <Typography sx={{ fontSize: "18px" }}><strong>Endereço de entrega:</strong> {getFormatEndereco_01(doc.enderecoEntrega)}</Typography>
                            <br />
                            <Typography sx={{ fontSize: "18px" }}><strong>Observação:</strong> {doc.informacaoComplementar}</Typography>
                        </Box>
                    }
                </>
            </Grid>
        </Grid>
    )
}
export default AdicionarNF;