import Typography from '@material-ui/core/Typography';
import StatusRoteirizacao from '../../Components/StatusRoteirizacao/StatusRoteirizacao';
import StatusRoute from '../../Components/StatusRoute';
import CapacidadeRota_Grid from './Components/CapacidadeRota_Grid';
import QuilometragemPrevista from './Components/QuilometragemPrevista';
//import CustoRota from './Components/CustoRota';
import { formatarAnoDataComHoras } from '../../Utils/FormatDateTime';

export const opcaoSituacao = [
    { value: 1, label: 'Aguardando início' },
    { value: 2, label: 'Em rota' }, 
    { value: 3, label: 'Finalizada' },
    { value: 5, label: 'Finalizada automaticamente' }, 
    //{ value: 4, label: 'Cancelada' }
];
export const opcaoSituacaoRoteirizacao = [
    { value: 0, label: '' }, 
    { value: 1, label: 'Aguardando Roteirização' }, 
    { value: 2, label: 'Roteirizado' },
    { value: 3, label: 'Em Roteirização' },
    { value: 4, label: 'Erro ao roteirizar' },
    { value: 5, label: 'Atenção - Ação necessária' },
];

export const columnsGrid = [
    { label: 'Região', field: 'rotaRegiaoDescricao', type: 'text', options: [], padding: 'default', width: '5px' },
    { label: 'Veículo', field: 'veiculoPlaca', type: 'customWithRow', options: [], padding: 'default', function: veiculo },
    { label: 'Motorista', field: 'motoristaNome', type: 'text', options: [], padding: 'default' },
    { label: 'Situação', field: 'status', type: 'custom', padding: 'default',function: StatusRoute,  width: '100px', align:"center" },
    { label: 'Roteirização', field: 'statusRoteirizacao', type: 'customWithRow', padding: 'default',function: StatusRoteirizacao, width: '100px',align:"center" },
    { label: 'Previsão de início', field: 'data', padding: 'default', type: "customWithRow", function: (row) => {return formatarAnoDataComHoras(row.data)} },
    { label: 'Início', field: 'dataInicio', padding: 'default', type: "customWithRow", function: (row) => {return formatarAnoDataComHoras(row.dataInicio)} },
    { label: 'Fim', field: 'dataFim', padding: 'default', type: "customWithRow", function: (row) => {return formatarAnoDataComHoras(row.dataFim)} },
    { label: 'Valor da Carga', field: 'valorTotalCarga2', padding: 'default', type: "customWithRow",  align: 'center', function: (row) => { return <CapacidadeRota_Grid row={row} isValorCarga={true}/>; }, },
    { label: 'Valor do Frete', field: 'valorTotalFrete', padding: 'default', type: "customWithRow", width: 170, function: (row) => formatarValor(row.valorTotalFrete)},
    { label: 'Paradas / Km Previsto', field: 'quantidadeDeParadas', padding: 'default', type: "customWithRow",  align: 'left', width: 150, function: designTrajeto},
    { label: 'Peso', field: 'pesoTotal', padding: 'default', type: "customWithRow",  align: 'center', function: (row) => { return <CapacidadeRota_Grid row={row} isPeso={true}/>; }, },
    { label: 'Cubagem', field: 'cubagemTotal', padding: 'default', type: "customWithRow",  align: 'center', function: (row) => { return <CapacidadeRota_Grid row={row} isCubagem={true} />; } },
    { label: 'Volume', field: 'volumeTotal', padding: 'default', type: "customWithRow", align: 'center', function: (row) => { return (row.volumeTotal==null?'':row.volumeTotal+' vol') } },
    
    //{ label: 'Custo', field: 'custo', padding: 'default', type: "customWithRow",  align: 'left', width: 150, function: custo},
];

export const camposForm = [
    { name: "rotaRegiaoId", label: "Região", type: "lookup_assincrono",endpoint:"api/RotaRegioes/lookup", size: 2, required: true, options: [] },
    { name: "veiculoId", label: "Veículo", type: "lookup", size: 2, required: true, options: [] },
    { name: "motoristaId", label: "Motorista", type: "lookup_assincrono",endpoint:"api/Motoristas/lookup", linkToCriar: false, size: 2, required: true, options: []},

    { name: "data", label: "Data e hora prevista de início", type: "datetime", size: 2, required: true },
    { name: "localInicioId", label: "Local para início", type: "lookup_assincrono",endpoint:"api/Locais/lookup", linkToCriar: false, size: 2, required: true, options: []},
    { name: "localFimId", label: "Local para fim", type: "lookup_assincrono",endpoint:"api/Locais/lookup", linkToCriar: false, size: 2, required: true, options: []},

    { name: "status", label: "Situação da rota", type: "option", size: 2, required: true ,  options: opcaoSituacao, disabled: true },
    { name: "statusRoteirizacao", label: "Situação da roteirização", type: "option", required: false, disabled: true, size: 2, options: opcaoSituacaoRoteirizacao },
    { name: "dataInicio", label: "Início", type: "datetime", size: 2, disabled: true },
    { name: "dataFim", label: "Fim", type: "datetime", size: 2, disabled: true },
    { name: "emBranco", label: "", size: 4, type: "component", disabled: true },
    { name: "pesoTotal", label: "Peso", type: "component", size: 2, disabled: true },
    { name: "cubagemTotal", label: "Cubagem", type: "component", size: 2, disabled: true },  
    { name: "volumeTotal", label: "Volumes", type: "component", size: 2, disabled: true},  
    { name: "valorTotalCarga", label: "Valor da Carga", type: "component", size: 2, disabled: true },    
    { name: "valorTotalFrete", label: "Valor do Frete", type: "component", size: 2, disabled: true },    
    { name: "quantidadeDeParadas", label: "Qtd de Paradas", type: "component", size: 1, disabled: true },  
    { name: "kmPrevisto", label: 'Km Previsto', type: "component", size: 1, disabled: true },    
    { name: "buttonStatusRoteirizacao", label: "", type: "component", size: 2 }
    
    //{ grupo: "Empresa/Log",name: "empresaId", label: "Empresa", type: "lookup", size: 9, required: true, options: [], disabled: true},
    //{ name: "dataCadastro", label: "Data do cadastro", type: "datetime", size: 3, disabled: true},
]

function veiculo(row){
    var t = row.veiculoPlaca+ ' ('+row.veiculoApelido+')';
    return t;
}

function formatarValor(valor){
  if(valor == null || valor === undefined || valor === 0){
    return <></>
  }
  return valor.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'	});
}

// function custo(row) {

// 	return <><div style={{ display: 'flex', alignItems: 'left' }}>
// 					  <CustoRota row={row}/>
// 			  </div>
// 			</>	
// }

function designTrajeto(row) {

	return <><div style={{ display: 'flex', alignItems: 'left' }}>
				<Typography variant="">
            {row.quantidadeDeParadas === 1? 
            <>{row.quantidadeDeParadas} parada</>:
            <>{row.quantidadeDeParadas} paradas</>
            }
            <br />
					  <QuilometragemPrevista row={row}/>
				</Typography>
			  </div>
			</>	
}