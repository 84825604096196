import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router-dom';

import SwapCalls from '@material-ui/icons/SwapHoriz';
import Tooltip from '@material-ui/core/Tooltip';
import { EnderecosNewRoute } from '../../Router/Routing';

export default function ButtonSubstituto(props) {
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClose2 = () => {
        setOpen(false);
        history.push(EnderecosNewRoute+"?enderecoOrigemId="+props.id+'&pessoaId='+props.pessoaId);
    };

    return (
        <div>
            <Tooltip title="Endereço incorreto? Substituir endereço">
                <Button type="submit" variant="outlined" color="primary" style={{}} startIcon={<SwapCalls />} onClick={handleClickOpen}>
                    Endereço incorreto? Substituir endereço
                </Button>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Deseja substituir esse endereço?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Caso tenha um endereço fiscal incorreto ou desatualizado, você pode substituir esse endereço por um novo. Nesse caso, novas entregas para o endereço fiscal serão automaticamente substituidas com o novo endereço. Deseja substituir esse endereço?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Não
                    </Button>
                    <Button onClick={handleClose2} color="primary" variant="contained" autoFocus>
                        SIM
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}