import { createContext, useState } from "react";

export const AjusteEnderecoContext = createContext();

export const AjusteEnderecoProvider = ({ children }) => {
    const [enderecos, setEnderecos] = useState([]);
    const [enderecoAjuste, setEnderecoAjuste] = useState(null);
    const [filtroApi, setFiltroApi] = useState({ page: 0, maxRegistro: 10 });
    const [expanded, setExpanded] = useState(0);
    const [coords, setCoords] = useState({ lat: 0, lng: 0, error: false });
    const [textCoords, setTextCoords] = useState(`${coords.lat}, ${coords.lng}`);

    const createEnderecoStr = (endereco) => {
        let endStr = "";

        if (endereco.logradouro) {
            endStr += `${endereco.logradouro}, `;
        }
        if (endereco.numero) {
            endStr += `${endereco.numero}, `;
        }
        if (endereco.bairro) {
            endStr += `${endereco.bairro}, `;
        }
        if (endereco.nomeMunicipio) {
            endStr += `${endereco.nomeMunicipio}, `;
        }
        if (endereco.sigaUF) {
            endStr += `${endereco.sigaUF}, `;
        }
        if (endereco.cep) {
            endStr += `${endereco.cep}`;
        }

        return endStr;
    }

    return (
        <AjusteEnderecoContext.Provider
            value={{
                createEnderecoStr,
                enderecos, setEnderecos,
                enderecoAjuste, setEnderecoAjuste,
                coords, setCoords,
                textCoords, setTextCoords,
                filtroApi, setFiltroApi,
                expanded, setExpanded
            }}
        >
            {children}
        </AjusteEnderecoContext.Provider>
    )
}