import { Box, Grid, Typography } from "@mui/material";
import { captalize, fonts } from "../utils/configs";
import { QRCodeSVG } from "qrcode.react";
import { EllipsisText } from "../utils/EllipsisText";
import { getFormatDataHora } from "../../../Utils/FormatDateTime";

export const EtiquetaKrambeck = ({ documento, volume, volumeTotal }) => {
    return (
        <Box
            sx={{
                width: "76mm",
                height: "102mm",
                padding: "2mm",
                overflow: "hidden",
                boxSizing: "border-box",
                pageBreakAfter: "always",
                position: "relative",
            }}
        >
            <Typography
                sx={{
                    fontSize: fonts.label,
                    textAlign: "center"
                }}
            >
                LOJA 01 - MATRIZ - {getFormatDataHora(new Date())}
            </Typography>

            <Box sx={{ margin: "1mm" }} />

            <Box
                sx={{
                    width: "100%",
                    height: "56px",
                    display: "flex",
                    alignItems: "center"
                }}
            >
                <EllipsisText maxLines={2} >
                    <Typography
                        sx={{

                            fontWeight: "bold",
                            lineHeight: "1",
                            fontSize: fonts.primary,
                        }}
                    >
                        {documento.destinatario.razaoSocial}
                    </Typography>
                </EllipsisText>
            </Box>

            <Box sx={{ margin: "1mm" }} />

            <Typography
                sx={{ fontSize: fonts.label }}
            >
                RUA
            </Typography>
            <EllipsisText maxLines={1}>
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: fonts.secondary,
                        lineHeight: "1"
                    }}
                >
                    {captalize(documento.enderecoEntrega.logradouro)}
                </Typography>
            </EllipsisText>

            <Box sx={{ margin: "1mm" }} />

            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Typography
                        sx={{ fontSize: fonts.label }}
                    >
                        NÚMERO
                    </Typography>
                    <EllipsisText maxLines={1}>
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                fontSize: fonts.secondary,
                                lineHeight: "1"
                            }}
                        >
                            {documento.enderecoEntrega.numero}
                        </Typography>
                    </EllipsisText>

                    <Box sx={{ margin: "1mm" }} />

                    <Typography
                        sx={{ fontSize: fonts.label }}
                    >
                        CIDADE
                    </Typography>
                    <EllipsisText maxLines={1}>
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                fontSize: fonts.secondary,
                                lineHeight: "1"
                            }}
                        >
                            {captalize(documento.enderecoEntrega.nomeMunicipio)}
                        </Typography>
                    </EllipsisText>
                </Grid>
                <Grid item xs={6}>
                    <Typography
                        sx={{ fontSize: fonts.label }}
                    >
                        BAIRRO
                    </Typography>
                    <EllipsisText maxLines={1}>
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                fontSize: fonts.secondary,
                                lineHeight: "1"
                            }}
                        >
                            {captalize(documento.enderecoEntrega.bairro)}
                        </Typography>
                    </EllipsisText>

                    <Box sx={{ margin: "1mm" }} />

                    <Typography
                        sx={{ fontSize: fonts.label }}
                    >
                        CÓD. DOCUMENTO
                    </Typography>
                    <EllipsisText maxLines={1}>
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                fontSize: fonts.secondary,
                                lineHeight: "1"
                            }}
                        >
                            {documento.numeroDocumentoFiscal}
                        </Typography>
                    </EllipsisText>
                </Grid>
            </Grid>

            <Box sx={{ margin: "1mm" }} />

            <Box sx={{ height: "30px" }}>
                <EllipsisText maxLines={3}>
                    <Typography
                        sx={{
                            textAlign: "justify",
                            fontSize: "10px",
                            lineHeight: "1",
                        }}
                    >
                        {documento.informacaoComplementar}
                    </Typography>
                </EllipsisText>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "2mm"
                }}
            >
                <Box sx={{ flex: 1 }} >
                    <QRCodeSVG
                        title={"Krambeck"}
                        // v = volume
                        // t = total
                        // doc = numero do doc
                        value={JSON.stringify({
                            v: Number(volume),
                            t: Number(volumeTotal),
                            doc: documento.numeroDocumentoFiscal
                        })}
                        size={128}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        style={{
                            width: "100%"
                        }}
                    />
                </Box>
                <Box sx={{ flex: 1 }} >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <Typography
                            sx={{ fontSize: fonts.label }}
                        >
                            SIGLA
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                fontSize: fonts.big,
                                lineHeight: "1",
                            }}
                        >
                            {documento.codigoEntrega}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <Typography
                            sx={{ fontSize: fonts.label }}
                        >
                            VOLUME
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                fontSize: fonts.big,
                                lineHeight: "1",
                            }}
                        >
                            {volume}/{volumeTotal}
                        </Typography>

                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    width: "100%",
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    display: "flex",
                    justifyContent: "center",
                    padding: "1mm 0"
                }}
            >
                <Typography
                    sx={{
                        textAlign: "center",
                        fontSize: "9px",
                        width: "100%"
                    }}
                >
                    www.krambeck.com.br - A loja completa em autopeças!
                </Typography>
            </Box>
        </Box>
    );
};