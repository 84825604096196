import React, { useCallback } from "react";

import TextField from '@material-ui/core/TextField';

import Autocomplete from '@material-ui/lab/Autocomplete';
import debounce from "lodash.debounce";

import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null, details: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));


export default function Asynchronous(props) {

  const {
    meta: { submitting, error, touched,invalid },
    input: { onBlur, /*value,*/ ...inputProps },
    required,
    validate,
    ref,
    label,
    disabled,
    change, formName,
    endpoint,
    ...others
  } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const key = 'AIzaSyCQvSNSEXS7tAtPkKeDE9xYafaL7hAbyrg';
      
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key='+key+'&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue,componentRestrictions: {country: 'br'} }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const changeHandler = (event, newInputValue) => {
    setInputValue(newInputValue);
};

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

  const buscarEndereco = (newValue) => {

    if (newValue == null || newValue.place_id == null)
      return;

    var placeService = new window.google.maps.places.PlacesService(document.querySelector('#google-maps'));
    placeService.getDetails({
      placeId: newValue.place_id,
      key: key,
      fields: ["name","address_components","formatted_phone_number", "geometry.location"]
    }, (data) => {
      try{
        change(formName,'logradouro', "");
        change(formName,'numero', "");
        change(formName,'bairro', "");
        change(formName,'nomeMunicipio', "");
        change(formName,'sigaUF', "");
        change(formName,'cep', "");
        change(formName,'localizacao', "");

      data.address_components.map(x=>{
        if (x.types.indexOf("route") > -1)
          change(formName,'logradouro', x.long_name);
        else if (x.types.indexOf("street_number") > -1)
          change(formName,'numero', x.long_name);
        else if (x.types.indexOf("sublocality_level_1") > -1)
          change(formName,'bairro', x.long_name);
        else if (x.types.indexOf("administrative_area_level_2") > -1)
          change(formName,'nomeMunicipio', x.long_name);
        else if (x.types.indexOf("administrative_area_level_1") > -1)
          change(formName,'sigaUF', x.short_name);
        else if (x.types.indexOf("postal_code") > -1)
          change(formName,'cep', x.long_name);
      })

      //change(formName,'n_latitude', data.geometry.location.lat().toString());
      //change(formName,'n_longitude', data.geometry.location.lng().toString());
      change(formName,'latitude', data.geometry.location.lat().toString());
      change(formName,'longitude', data.geometry.location.lng().toString());
      change(formName,'localizacao', { lat: data.geometry.location.lat().toString(), lng: data.geometry.location.lng().toString()});
    }catch(e){}
    });
    
  } 
  return (
    <>
      <Autocomplete
        id="google-map-demo"
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        disabled={disabled}
        loadingText='Carregando...'
        noOptionsText='Nenhum registro encontrado'
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          
          setValue(newValue);
          buscarEndereco(newValue);
        }}
        onInputChange={debouncedChangeHandler}
        renderInput={(params) => (
          <TextField {...params} label={label} variant="outlined" fullWidth />
        )}
        renderOption={(option) => {
          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                  <span style={{ fontWeight: 700 }}>
                    {option.structured_formatting.main_text}
                  </span>

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </>
  );
}
