import React from 'react';
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Main from '../Pages/Main';
import InjectAxiosInterceptors from './InjectAxiosInterceptors';
import { PodeAcessarRelatorios, UsuarioComPermissao, UsuarioComPermissaoComToken, VerificarTokenExpirado, VerificarTokenExpirado2, getToken } from './auth';


import Mapa from '../Pages/Mapa/VeiculosMaps';

import MonitoramentoPage from '../Pages/Monitoramento/Monitoramento';

import Login from '../Pages/Login/Login2';

import AcessoNegado from '../Pages/AcessoNegado/AcessoNegado'
import EnviarXmlPorEmail from '../Pages/EnviarXMLPorEmail/EnviarXmlPorEmail';

import DocumentoImportarForm from '../Pages/DocumentoImportar/Form';
import UsuariosForm from '../Pages/Usuario/Form';
import Usuarios from '../Pages/Usuario/GridList';

import EmpresasForm from '../Pages/Empresa/Form';
import Empresas from '../Pages/Empresa/GridList';

import LocaisForm from '../Pages/Local/Form';
import Locais from '../Pages/Local/GridList';

import RegioesForm from '../Pages/RotaRegiao/Form';
import Regioes from '../Pages/RotaRegiao/GridList';

import VeiculosForm from '../Pages/Veiculo/Form';
import Veiculos from '../Pages/Veiculo/GridList';

import ClientesForm from '../Pages/Cliente/Form';
import Clientes from '../Pages/Cliente/GridList';

import Aplicativo from '../Pages/Aplicativo';
import Cadastro from '../Pages/Cadastros/Grid';

import RotasForm from '../Pages/Rota/Form';
import Rotas from '../Pages/Rota/GridList';
import RotasSelectEntregas from '../Pages/RotasSelectEntregas';
import RotasSelectEntregasComMapa from '../Pages/RotasSelectEntregasComMapa';
import RotasTransfeirComMapa from '../Pages/RotasTransfeirComMapa';
import RotasSelectLocaisPlanejados from '../Pages/RotasSelectLocaisPlanejados';

import Pagamentos from '../Pages/Pagamento/GridList';

import Relatorios from '../Pages/Relatorios/GridList';

import PacoteEvidencias from '../Pages/PacoteEvidencias/GridList';
import PacoteEvidenciaDocumentoForm from '../Pages/PacoteEvidenciaDocumento/Form';

import EntregasForm2 from '../Pages/Entrega/Form2';
import Entregas from '../Pages/Entrega/GridList';

import ParadaPlanejadaForm from '../Pages/ParadaPlanejada/Form2';

import BackendPage from '../Pages/BackendPage/BackendPage';

import DocumentosForm from '../Pages/Documentos/Form';
import NotasFiscais from '../Pages/Documentos/NotaFiscal/GridList';

import Evidencias from '../Pages/Evidencia/GridList';
//import EvidenciasForm from '../Pages/Evidencia/Form';

import DestinatarioForm from '../Pages/Destinatario/Form';
import EnderecoForm from '../Pages/Endereco/Form';
import RelatorioForm from '../Pages/Relatorios/Form';

import BuscaCompleta from "../Pages/BuscaCompleta/BuscaCompleta";

import { Roles } from '../Utils/Roles';

import { BuscaCompletaProvider } from '../Context/BuscaCompletaContext';
import { NFProvider } from '../Context/NFContext';
import { AjusteEnderecoProvider } from '../Context/AjusteEnderecoContext';
import { MonitoramentoProvider } from '../Context/MonitoramentoContext';
import { SequenciaFixadaProvider } from '../Context/SequenciaFixadaContext';


import AjusteEndereco from '../Pages/AjusteEndereco/AjsuteEndereco';
import StatusRoteirizadorPage from '../Pages/StatusRoteirizador'
import queryString from 'query-string';
import Etiqueta from '../Pages/Etiqueta/Etiqueta';

export const LoginRoute = "/login";

export const AcessoNegadoRoute = "/acessoNegado"
export const EnviarXmlPorEmailRoute = "/app/enviarXmlPorEmail"

export const AjusteEnderecoRouteStr = "/ajusteEndereco"
export const StatusRoteirizador = "/statusRoteirizador"

export const HomeRoute = "/";
export const MapasRoute = "/mapa";

export const MonitoramentoRoute = "/monitoramento";

export const UsuariosRoute = "/usuarios";
export const UsuariosNewRoute = "/usuarios/new";
export const UsuariosEditRoute = "/usuarios/edit/";

export const DocumentoImportarEditRoute = "/documentoImportar/edit/";

export const EmpresasRoute = "/empresas";
export const EmpresasNewRoute = "/empresas/new";
export const EmpresasEditRoute = "/empresas/edit/";

export const LocaisRoute = "/locais";
export const LocaisNewRoute = "/locais/new";
export const LocaisEditRoute = "/locais/edit/";

export const RegioesRoute = "/regioes";
export const RegioesNewRoute = "/regioes/new";
export const RegioesEditRoute = "/regioes/edit/";

export const AplicativosRoute = "/aplicativo";
export const CadastrosRoute = '/cadastros';

export const VeiculosRoute = "/veiculos";
export const VeiculosNewRoute = "/veiculos/new";
export const VeiculosEditRoute = "/veiculos/edit/";

export const ClientesRoute = "/clientes";
export const ClientesNewRoute = "/clientes/new";
export const ClientesEditRoute = "/clientes/edit/";

export const RotasRoute = "/rotas";
export const RotasNewRoute = "/rotas/new";
export const RotasEditRoute = "/rotas/edit/";
export const RotasSelectEntregasRoute = "/rotas/selectEntregas/";

export const RotasSelectEntregasComMapaRoute = "/rotas/selectEntregasComMapa/";
export const RotasTransfeirComMapaRoute = "/rotas/transferirComMapa/";
export const RotasSelectLocaisPlanejadosRoute = "/rotas/selectLocaisPlanejados/";

export const EntregasRoute = "/entregas";
export const EntregasNewRoute = "/entregas/new";
export const EntregasNewRoute2 = "/entregas/new2";
export const EntregasEditRoute = "/entregas/edit/";
export const EntregasEditRoute2 = "/entregas/edit2/";

export const ParadaPlanejadasRoute = "/paradaplanejada";
export const ParadaPlanejadaNewRoute = "/paradaplanejada/new";
export const ParadaPlanejadaEditRoute = "/paradaplanejada/edit/";

export const DocumentosRoute = "/documentos";
export const DocumentosNewRoute = "/notasfiscais/new";
export const DocumentosEditRoute = "/documentos/edit/";

export const EvidenciasRoute = "/evidencias";
export const EvidenciasEditRoute = "/evidencias/edit/";

export const EnderecosRoute = "/enderecos";
export const EnderecosNewRoute = "/enderecos/new";
export const EnderecosEditRoute = "/enderecos/edit/";

export const DestinatariosRoute = "/destinatarios";
export const DestinatariosNewRoute = "/destinatarios/new";
export const DestinatariosEditRoute = "/destinatarios/edit/";

export const buscaCompletaRoute = "/buscaCompleta";

export const PagamentosRoute = "/pagamentos";

export const RelatoriosRoute = "/relatorios";
export const RelatoriosNewRoute = "/relatorios/new";

export const PacoteEvidenciasRoute = "/pacotesEvidencias";
export const PacoteEvidenciasRouteNew = "/pacotesEvidencias/new";
export const PacoteEvidenciasRouteEdit = "/pacotesEvidencias/edit";

export const BackendRoute = "/backend";

export const EtiquetaRoute = "/etiqueta";

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
	<Route {...rest}
		render={props => !VerificarTokenExpirado() && UsuarioComPermissao(roles) ? (
			<Component {...props} />
		) : !UsuarioComPermissao(roles) && getToken() != null ? (
			<Redirect to={{ pathname: AcessoNegadoRoute, state: { from: props.location } }} />
		) : (<Redirect to={{ pathname: LoginRoute }} />)
		}
	/>
)

const PrivateAppRoute = ({ component: Component, roles, ...rest }) => (
	<Route
		{...rest}
		render={props => {
			const { location } = props;
			const { pathname, search } = location;
			const queryParams = queryString.parse(search);

			if (pathname.startsWith('/app/') && !VerificarTokenExpirado2(queryParams.token) && UsuarioComPermissaoComToken(roles, queryParams.token)) {
				sessionStorage.setItem('token', queryParams.token);
				return <Component {...props} />;
			}

			// Redirecionar para uma página de acesso negado
			return <Redirect to={{ pathname: AcessoNegadoRoute, state: { from: props.location } }} />;
		}}
	/>
);

class Routing extends React.Component {
	render() {
		return (
			<>
				<InjectAxiosInterceptors />
				<Switch>
					<Route path={LoginRoute} exact component={Login} />
					<Route path={AcessoNegadoRoute} component={AcessoNegado} />

					<PrivateAppRoute path={EnviarXmlPorEmailRoute} component={EnviarXmlPorEmail} roles={[Roles.Suporte, Roles.Motorista]} />

					<Switch>
						<Main>
							<MonitoramentoProvider>
								<PrivateRoute path={HomeRoute} exact component={MonitoramentoPage} roles={[Roles.Logistica, Roles.Monitoramento, Roles.Master, Roles.Suporte]} />
								<PrivateRoute path={MonitoramentoRoute} component={MonitoramentoPage} roles={[Roles.Logistica, Roles.Monitoramento, Roles.Master, Roles.Suporte]} />
							</MonitoramentoProvider>
							<PrivateRoute path={MapasRoute} component={Mapa} roles={[Roles.Suporte]} />
							<PrivateRoute path={EntregasRoute} component={EntregaRoute} />
							<PrivateRoute path={ParadaPlanejadasRoute} component={ParadaPlanejadaRoute} roles={[Roles.Master, Roles.Suporte, Roles.Logistica]} />
							<PrivateRoute path={DestinatariosRoute} component={DestinatarioRoute} roles={[Roles.Master, Roles.Suporte, Roles.Logistica]} />
							<PrivateRoute path={PagamentosRoute} component={PagamentoRoute} roles={[Roles.Master, Roles.Suporte]} />
							<PrivateRoute path={EnderecosRoute} component={EnderecoRoute} roles={[Roles.Master, Roles.Suporte, Roles.Logistica]} />
							<PrivateRoute path={DocumentosRoute} component={DocumentoRoute} roles={[Roles.Master, Roles.Suporte, Roles.Logistica, Roles.Monitoramento]} />
							<PrivateRoute path={EvidenciasRoute} component={EvidenciaRoute} roles={[Roles.Suporte]} />
							<PrivateRoute path={RotasRoute} component={RotaRoute} roles={[Roles.Master, Roles.Suporte, Roles.Logistica, Roles.Monitoramento]} />
							<PrivateRoute path={UsuariosRoute} component={Usuario} roles={[Roles.Master, Roles.Suporte]} />
							<PrivateRoute path={DocumentoImportarEditRoute} component={DocumentoImportar} roles={[Roles.Master, Roles.Suporte, Roles.Logistica]} />
							<PrivateRoute path={buscaCompletaRoute} component={BuscaCompletaRoute} roles={[Roles.Logistica, Roles.Monitoramento, Roles.Master, Roles.Suporte]} />
							<PrivateRoute path={EmpresasRoute} component={EmpresaRoute} roles={[Roles.Suporte]} />
							<PrivateRoute path={LocaisRoute} component={LocalRoute} roles={[Roles.Master, Roles.Suporte]} />
							<PrivateRoute path={RegioesRoute} component={RegiaoRoute} roles={[Roles.Master, Roles.Suporte]} />
							<PrivateRoute path={VeiculosRoute} component={VeiculoRoute} roles={[Roles.Master, Roles.Suporte]} />
							<PrivateRoute path={ClientesRoute} component={ClienteRoute} roles={[Roles.Master, Roles.Suporte, Roles.Logistica]} />
							<PrivateRoute path={AplicativosRoute} component={AplicativoRoute} />
							<PrivateRoute path={CadastrosRoute} component={CadastroRoute} />
							<PrivateRoute path={RelatoriosRoute} component={RelatorioRoute} />
							<PrivateRoute path={PacoteEvidenciasRoute} component={PacotesEvidenciasRoute} roles={[Roles.Master, Roles.Suporte]} />
							<PrivateRoute path={BackendRoute} component={BackendPage} roles={[Roles.Suporte]} />
							<PrivateRoute path={AjusteEnderecoRouteStr} component={AjusteEnderecoRoute} roles={[Roles.Suporte]} />
							<PrivateRoute path={StatusRoteirizador} component={StatusRoteirizadorRoute} roles={[Roles.Suporte]} />
							<PrivateRoute path={EtiquetaRoute} component={EtiquetaPageRoute} roles={[Roles.Suporte]} />
						</Main>
					</Switch>
				</Switch>
			</>
		);
	}
}

class Usuario extends React.Component {
	render() {
		return <div>
			<Route exact path={UsuariosRoute} component={Usuarios} />
			<Route path={UsuariosNewRoute} component={UsuariosForm} />
			<Route path={UsuariosEditRoute + ":id"} component={UsuariosForm} />
		</div>
	}
}
class DocumentoImportar extends React.Component {
	render() {
		return <div>
			<Route exact path={DocumentoImportarEditRoute + ":id"} component={DocumentoImportarForm} />
		</div>
	}
}

class EmpresaRoute extends React.Component {
	render() {
		return <div>
			<Route exact path={EmpresasRoute} component={Empresas} />
			<Route path={EmpresasNewRoute} component={EmpresasForm} />
			<Route path={EmpresasEditRoute + ":id"} component={EmpresasForm} />
		</div>
	}
}
class ClienteRoute extends React.Component {
	render() {
		return <div>
			<Route exact path={ClientesRoute} component={Clientes} />
			<Route path={ClientesNewRoute} component={ClientesForm} />
			<Route path={ClientesEditRoute + ":id"} component={ClientesForm} />
		</div>
	}
}
class VeiculoRoute extends React.Component {
	render() {
		return <div>
			<Route exact path={VeiculosRoute} component={Veiculos} />
			<Route path={VeiculosNewRoute} component={VeiculosForm} />
			<Route path={VeiculosEditRoute + ":id"} component={VeiculosForm} />
		</div>
	}
}
class AplicativoRoute extends React.Component {
	render() {
		return <div>
			<Route exact path={AplicativosRoute} component={Aplicativo} />
		</div>
	}
}
class CadastroRoute extends React.Component {
	render() {
		return <div>
			<Route exact path={CadastrosRoute} component={Cadastro} />
		</div>
	}
}

class LocalRoute extends React.Component {
	render() {
		return <div>
			<Route exact path={LocaisRoute} component={Locais} />
			<Route path={LocaisNewRoute} component={LocaisForm} />
			<Route path={LocaisEditRoute + ":id"} component={LocaisForm} />
		</div>
	}
}
class RegiaoRoute extends React.Component {
	render() {
		return <div>
			<Route exact path={RegioesRoute} component={Regioes} />
			<Route path={RegioesNewRoute} component={RegioesForm} />
			<Route path={RegioesEditRoute + ":id"} component={RegioesForm} />
		</div>
	}
}

class RotaRoute extends React.Component {
	render() {
		return (
			<div>
				<NFProvider>
					<SequenciaFixadaProvider>
						<Route exact path={RotasRoute} component={Rotas} />
						<PrivateRoute path={RotasNewRoute} component={RotasForm} roles={[Roles.Logistica, Roles.Master, Roles.Suporte]} />
						<Route path={RotasEditRoute + ":id"} component={RotasForm} />
						<PrivateRoute path={RotasSelectEntregasRoute + ":id"} component={RotasSelectEntregas} roles={[Roles.Logistica, Roles.Master, Roles.Suporte]} />
						<PrivateRoute path={RotasSelectEntregasComMapaRoute + ":id"} component={RotasSelectEntregasComMapa} roles={[Roles.Logistica, Roles.Master, Roles.Suporte]} />
						<PrivateRoute path={RotasTransfeirComMapaRoute + ":id"} component={RotasTransfeirComMapa} roles={[Roles.Logistica, Roles.Master, Roles.Suporte]} />
						<PrivateRoute path={RotasSelectLocaisPlanejadosRoute + ":id"} component={RotasSelectLocaisPlanejados} roles={[Roles.Logistica, Roles.Master, Roles.Suporte]} />
					</SequenciaFixadaProvider>
				</NFProvider>
			</div>
		)
	}
}

class EntregaRoute extends React.Component {
	render() {
		return <div>
			<PrivateRoute exact path={EntregasRoute} component={Entregas} roles={[Roles.Suporte, Roles.Master, Roles.Logistica]} />
			<PrivateRoute path={EntregasNewRoute2} component={EntregasForm2} roles={[Roles.Master, Roles.Suporte, Roles.Logistica]} />
			<Route path={EntregasEditRoute2 + ":id"} component={EntregasForm2} roles={[Roles.Master, Roles.Suporte, Roles.Logistica, Roles.Monitoramento]} />
		</div>
	}
}

class ParadaPlanejadaRoute extends React.Component {
	render() {
		return <div>
			<Route path={ParadaPlanejadaNewRoute} component={ParadaPlanejadaForm} />
			<Route path={ParadaPlanejadaEditRoute + ":id"} component={ParadaPlanejadaForm} />
		</div>
	}
}

class PagamentoRoute extends React.Component {
	render() {
		return <div>
			<Route exact path={PagamentosRoute} component={Pagamentos} />
		</div>
	}
}

const RelatorioRouteController = ({ component: Component, roles, ...rest }) => {
	if (PodeAcessarRelatorios()) {
		return (
			<Route {...rest}
				render={props => !VerificarTokenExpirado() ? (
					<Component {...props} />
				) : (<Redirect to={{ pathname: LoginRoute }} />)
				}
			/>
		);
	} else {
		return (
			<Route {...rest}
				render={props => !VerificarTokenExpirado() ? (<Redirect to={{ pathname: AcessoNegadoRoute }} />) : (<Redirect to={{ pathname: LoginRoute }} />)
				}
			/>
		);

	}

}

class RelatorioRoute extends React.Component {
	render() {
		return <div>
			<RelatorioRouteController exact path={RelatoriosRoute} component={Relatorios} />
			<RelatorioRouteController path={RelatoriosNewRoute} component={RelatorioForm} />
		</div>
	}
}
class DestinatarioRoute extends React.Component {
	render() {
		return <div>
			<Route path={DestinatariosNewRoute} component={DestinatarioForm} />
			<Route path={DestinatariosEditRoute + ":id"} component={DestinatarioForm} />
		</div>
	}
}
class EnderecoRoute extends React.Component {
	render() {
		return <div>
			<Route path={EnderecosNewRoute} component={EnderecoForm} />
			<Route path={EnderecosEditRoute + ":id"} component={EnderecoForm} />
		</div>
	}
}

class DocumentoRoute extends React.Component {
	render() {
		return <div>
			<PrivateRoute exact path={DocumentosRoute} component={NotasFiscais} roles={[Roles.Suporte, Roles.Master, Roles.Logistica]} />
			<PrivateRoute path={DocumentosNewRoute} component={DocumentosForm} roles={[Roles.Suporte, Roles.Master, Roles.Logistica]} />
			<PrivateRoute path={DocumentosEditRoute + ":id"} component={DocumentosForm} roles={[Roles.Suporte, Roles.Master, Roles.Logistica, Roles.Monitoramento]} />
		</div>
	}
}

class EvidenciaRoute extends React.Component {
	render() {
		return <div>
			<Route exact path={EvidenciasRoute} component={Evidencias} />
			{/* <Route path={EvidenciasEditRoute+":id"} component={EvidenciasForm} /> */}
		</div>
	}
}

class BuscaCompletaRoute extends React.Component {
	render() {
		return (
			<div>
				<BuscaCompletaProvider>
					<Route exact path={buscaCompletaRoute} component={BuscaCompleta} />
				</BuscaCompletaProvider>
			</div>
		)
	}
}

class PacotesEvidenciasRoute extends React.Component {
	render() {
		return (
			<>
				<Route exact path={PacoteEvidenciasRoute} component={PacoteEvidencias} />
				<Route exact path={PacoteEvidenciasRouteNew} component={PacoteEvidenciaDocumentoForm} />
				<Route exact path={`${PacoteEvidenciasRouteEdit}/:id`} component={PacoteEvidenciaDocumentoForm} />
			</>
		)
	}
}

class AjusteEnderecoRoute extends React.Component {
	render() {
		return (
			<AjusteEnderecoProvider>
				<Route exact path={AjusteEnderecoRouteStr} component={AjusteEndereco} />
			</AjusteEnderecoProvider>
		)
	}
}
class StatusRoteirizadorRoute extends React.Component {
	render() {
		return (
			<Route exact path={StatusRoteirizador} component={StatusRoteirizadorPage} />
		)
	}
}

class EtiquetaPageRoute extends React.Component {
	render() {
		return (
			<Route exact path={EtiquetaRoute} component={Etiqueta} />
		)
	}
}

export default withRouter(Routing);
