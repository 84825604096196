export const opcaoSituacao = [
    { value: 1, label: 'Entregue' },
    { value: 2, label: 'Devolvido' }, 
    { value: 3, label: 'EntregueParcial' }, 
    { value: 4, label: 'Cancelada' },
    { value: 5, label: 'Reagendada' },
    { value: 7, label: 'Aguardando' }];

export const camposForm = [
    { name: "destinatario.descricao", label: "Destinatário", type: "text", size: 12, required: true, disabled: true},
    { name: "chave", label: "Chave", type: "text", size: 4, required: true, disabled: true},
    { name: "numeroDocumentoFiscal", label: "Número", type: "text", size: 3, disabled: true},
    { name: "idExterno", label: "Número Externo", type: "text", size: 1, disabled: true},
    { name: "naturezaOperacao", label: "Natureza da operação", type: "text", size: 4,  disabled: true},
    { label: 'Total', name: 'total.valorTotal', type: 'money',  size: 2 , disabled: true, numeric: true},
    { name: "dataEmissao", label: "Data Emissão", type: "date", size: 2 , disabled: true},
    { name: "dataSaidaEntrada", label: "Data Saida/Entrada", type: "date", size: 2 , disabled: true},
    { name: "tipoOperacao", label: "Operacao", type: "option", size: 2, required: false, options: [{ value: 0, label: 'Entrada' }, { value: 1, label: 'Saída' }], disabled: true },
    { name: "pesoBruto", label: "Peso bruto", type: "peso", size: 2 , disabled: true},
    { name: "quantidadeVolume", label: "Quantidade de volume", type: "text", size: 2 , disabled: true},
    { name: "formaPagamento", label: "Forma de pagamento", type: "text", size: 2 , disabled: true},
    { name: "informacaoComplementar", label: "Informação complementar", type: "text", multiline: true, rowsMax: 4, size: 12 , disabled: true},
    {  name: "produtosServicos", type: "component", component: '', size: 12 , disabled: true},
]
