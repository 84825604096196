import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Box, Chip, Skeleton, TablePagination } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { getAllForAjusteEnderecoManual } from '../../../../API/Enderecos';
import { AjusteEnderecoContext } from '../../../../Context/AjusteEnderecoContext';
import { getRotasIdsForAjusteEnderecoManual } from '../../../../API/Rotas';
import { getFormatDataTimeToString } from '../../../../Utils/FormatDateTime';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const EnderecoList = () => {
    const {
        enderecos,
        setEnderecos,
        createEnderecoStr,
        enderecoAjuste,
        setEnderecoAjuste,
        expanded,
        setExpanded,
        filtroApi,
        setFiltroApi
    } = useContext(AjusteEnderecoContext);

    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [rotaIds, setRotaIds] = useState([]);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleChangePage = (event, newPage) => {
        setExpanded(0);
        setFiltroApi(prevState => ({
            ...prevState,
            page: newPage
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setFiltroApi(prevState => ({
            ...prevState,
            maxRegistro: parseInt(event.target.value),
            page: 0
        }));
    };

    const handleChipColor = (item) => {
        if (item.ok) {
            return "success";
        }

        if (item.checkOsm < 2) {
            return "error";
        }

        if (item.checkOsm >= 2) {
            return "warning";
        }
    }

    useEffect(() => {
        const fetchData = () => {
            try {
                setLoading(true);

                getAllForAjusteEnderecoManual(filtroApi)
                    .then(resp => {
                        setCount(resp.count);
                        setEnderecos(resp.data);
                        setEnderecoAjuste(resp.data[0]);
                    });

                setLoading(false);
            } catch {
                setEnderecos([]);
            }
        }

        fetchData();
    }, [filtroApi, setEnderecoAjuste, setEnderecos]);

    useEffect(() => {
        const fetchData = () => {
            try {
                getRotasIdsForAjusteEnderecoManual(enderecoAjuste.id, enderecoAjuste.empresaId)
                    .then(resp => {
                        setRotaIds([...new Set(resp)]);
                    });
            } catch {
                setRotaIds([]);
            }
        }

        fetchData();
    }, [enderecoAjuste]);

    if (loading) {
        return (
            <Box>
                {[1, 2, 3, 4].map((index) => (
                    <Skeleton
                        key={index}
                        variant="rounded"
                        width={"100%"}
                        height={60}
                        sx={{
                            margin: "10px 0px"
                        }}
                    />
                ))}
            </Box>
        )
    }

    if (!enderecos || enderecos.length === 0) {
        return (
            <Box
                sx={{
                    width: "100%",
                    margin: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Typography
                    variant="h4"
                >
                    Nenhum endereço encontrado
                </Typography>
            </Box>
        )
    }

    return (
        <Box>
            {enderecos.map((item, index) => (
                <Accordion
                    key={index}
                    expanded={expanded === index}
                    onChange={handleChange(index)}
                    onClick={() => setEnderecoAjuste(item)}
                >
                    <AccordionSummary>
                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"

                        }}>
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    marginRight: "15px"
                                }}
                            >
                                {createEnderecoStr(item)}
                            </Typography>
                            <Chip
                                sx={{
                                    height: 'auto',
                                    padding: "5px",
                                }}
                                label={
                                    <Typography
                                        sx={{
                                            fontSize: "14px"
                                        }}
                                    >
                                        {item.empresaId}
                                    </Typography>
                                }
                                color={handleChipColor(item)}
                            />
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            CreatedDate: {getFormatDataTimeToString(item.createdDate)}
                        </Typography>
                        <Typography>
                            UpdatedDate: {getFormatDataTimeToString(item.updatedDate)}
                        </Typography>
                        <Typography>
                            {(rotaIds != null && rotaIds.length > 0) && (
                                <>
                                    <span>RotaIds: </span>
                                    {rotaIds.map((item, index) => {
                                        return <span
                                            key={index}
                                            style={{
                                                margin: "0px 5px"
                                            }}
                                        >
                                            {item}
                                        </span>
                                    })}
                                </>
                            )}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
            <Accordion>
                <AccordionSummary
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end"
                    }}
                >
                    <TablePagination
                        count={count}
                        page={filtroApi.page}
                        onPageChange={handleChangePage}
                        rowsPerPage={filtroApi.maxRegistro}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </AccordionSummary>
            </Accordion>
        </Box>
    );
}