import React, { Component } from 'react'
import Form from '../../Components/Form/Form';
import { post, put, getByIdAdmin } from '../../API/Empresa';
import { SubmissionError } from 'redux-form';

const adicionarParadaDuranteRota = [
    { value: 0, label: 'Não permitido' },
    { value: 1, label: 'Permitido e adicionar no final da rota' },
    { value: 2, label: 'Permitido e roteirizar as não realizadas' },
];

const campos = [
    { name: "nome", label: "Nome", type: "text", size: 4, required: true, autoFocus: true },
    { name: "prefixo", label: "Prefixo", type: "text", size: 2, required: true, autoFocus: true },
    { name: "documento", label: "CNPJ/CPF", type: "text", size: 2, required: true },
    { name: "ativo", label: "Ativo", type: "bool", size: 2 },
    { name: "tipo", label: "Tipo", type: "option", required: true, size: 2, options: [{ value: 1, label: 'Entregas (NF/pedidos)' }, { value: 2, label: 'Outros' }] },

    { grupo: "Informações para contato", name: "email", label: "E-mail", type: "email", size: 3 },
    { name: "emailSuporte", label: "E-mail suporte", type: "email", size: 3 },
    { name: "emailFinanceiro", label: "E-mail financeiro", type: "email", required: true, size: 2 },
    { name: "telefone1", label: "Telefone 1", type: "text", required: true, size: 2 },
    { name: "telefone2", label: "Telefone 2", type: "text", size: 2 },

    { grupo: 'Endereço', name: "cep", label: "CEP", type: "text", required: false, size: 2 },
    { name: "endereco", label: "Endereço", type: "text", size: 3 },
    { name: "numero", label: "Numero", type: "text", size: 1 },
    { name: "complemento", label: "Complemento", type: "text", size: 2 },
    { name: "cidade", label: "Cidade", type: "text", size: 2 },
    { name: "estado", label: "Estado", type: "text", size: 1 },
    { name: "pais", label: "Pais", type: "text", size: 1 },

    { grupo: "Observações", name: "observacao", label: "Observação", type: "text", multiline: true, rowsMax: 4, size: 6 },
    { name: "observacaoContato", label: "Observação para contato", type: "text", multiline: true, rowsMax: 4, size: 6 },

    { grupo: "Roteirização e monitoramento", name: "tipoRoteirizacao", label: "Tipo", type: "option", required: true, size: 2, options: [{ value: 0, label: 'Desativado' }, { value: 1, label: 'Padrão - Menor distancia' }/*, { value: 2, label: 'Locais Dinâmicos' }*/] },
    { name: "localInicioFimId", label: "Sugestão de local para início e fim de rota", type: "lookup_assincrono", endpoint: "api/Locais/lookup", linkToCriar: false, size: 3, required: false, options: [] },
    { name: "inicioRota", label: "Hora de início da rota", type: "time", size: 1 },

    { name: "tipoRastreador", label: "Tipo de rastreador", type: "option", size: 2, options: [{ value: 2, label: 'Aplicativo - Grafos Tech' }, { value: 1, label: 'Tecnoraster' }, { value: 3, label: 'Orsegups' }] },

    { name: "raioParada", label: "Raio para identificar qualquer parada(metros)", type: "text", size: 3 },
    { name: "tempoRaioParada", label: "Tempo dentro do raio para identificar paradas (minutos)", type: "text", size: 3 },
    { name: "raioEntrega", label: "Raio para identificar parada de entrega (metros)", type: "text", size: 3 },
    { name: "velocidadeMinimaParaIgnorarParadas", label: "Velocidade minima para ignorar paradas (metros por segundos - m/s)", type: "text", size: 3 },
    { name: "adicionarParadaDuranteRota", label: "Adicionar parada durante a rota?", type: "option", size: 3, options: adicionarParadaDuranteRota },

    { grupo: "Importação", name: "importarNFSemPlaca", label: "Importar NF sem placa", type: "bool", size: 2 },
    { name: "utilizaCadastroRotaRegiao", label: "Utiliza cadastro de regiao?", type: "bool", size: 2 },

    { grupo: "Aplicativo do motorista - Grafos App", name: "evidenciaPermitida", label: "Permitir registrar evidências?", type: "bool", size: 2 },
    { name: "evidenciaObrigatoria", label: "Registrar evidência é obrigatório?", type: "bool", size: 2 },
    { name: "chegadaAoClienteObrigatoria", label: "Cronômetro é obrigatório?", type: "bool", size: 2 },
    { name: "motivoDevolucaoObrigatorio", label: "Motivos de devolução é obrigatório?", type: "bool", size: 2 },
    { name: "registroQuilometragemObrigatorio", label: "Registro de quilometragem obrigatório?", type: "bool", size: 2 },
    
    { grupo: "Tipos de parada (1 - Entrega, 2 - Recolha, 3 - Visita)" ,name: "tiposParadasPlanejadas", label: "Tipos de parada", type: "text", size: 3 },

]

class FormConfig extends Component {

    constructor(props) {
        super(props);
        let id = this.props.match.params.id;
        let l = false;
        if (id)
            l = true;
        this.state = { alertSucess: false, loading: l, values: {} };
    }

    create = async values => {
        var ss = values.inicioRota;

        if (values.inicioRota)
            values.inicioRota = new Date('2020-01-01T' + values.inicioRota + ':00Z')

        var retorno = await post(values);

        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
        } else {
            throw new SubmissionError({ name: 'User does not exist', _error: 'Login failed!' })
        }
        values.inicioRota = ss;
    }

    update = async values => {
        var ss = values.inicioRota;

        if (values.inicioRota)
            values.inicioRota = new Date('2020-01-01T' + values.inicioRota + ':00Z')

        var id = this.props.match.params.id;
        try {
            var retorno = await put(id, values);

            if (retorno.result === 'ok') {
                this.setState({ alertSucess: true });
            } else {
                throw new SubmissionError({ name: 'Erro ao salvar', _error: 'Erro ao salvar!' })
            }
        } catch (e) {
            throw new SubmissionError({ name: 'Erro ao salvar', _error: 'Erro ao salvar!' })
        }
        values.inicioRota = ss;
    }

    limpar = () => {
        this.setState({ alertSucess: false });
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        if (id) {
            getByIdAdmin(id).then((value) => {
                if (value !== undefined) {
                    if (value.inicioRota)
                        value.inicioRota = value.inicioRota.split('T')[1].slice(0, -3);
                    this.setState({ values: value });
                }
                this.setState({ loading: false });
            });
        } else {
            this.setState({
                values: {
                    ativo: true, tipo: 1, pais: 'Brasil', tipoRoteirizacao: 1, inicioRota: '08:00',
                    tipoRastreador: 2,
                    raioParada: '50',
                    tempoRaioParada: '2.8',
                    raioEntrega: '100',
                    velocidadeMinimaParaIgnorarParadas: '2',
                    importarNFSemPlaca: true,
                    evidenciaPermitida: true,
                    utilizaCadastroRotaRegiao: true,
                    evidenciaObrigatoria: false,
                    chegadaAoClienteObrigatoria: false,
                    motivoDevolucaoObrigatorio: false,
                    tiposParadasPlanejadas: '1',
                    adicionarParadaDuranteRota: 2
                }
            });
        }
    }

    render() {
        let id = this.props.match.params.id;
        if (id) {
            return (
                <Form title="Editar Empresa" form="editEmpresa" fields={campos} onSubmit={this.update} AlertSucess={this.state.alertSucess} initialValues={this.state.values} loading={this.state.loading} limpar={this.limpar}>
                </Form>
            );
        } else {
            return (
                <Form title="Nova Empresa" form="newEmpresa" fields={campos} onSubmit={this.create} AlertSucess={this.state.alertSucess} initialValues={this.state.values} limpar={this.limpar}>
                </Form>
            );
        }
    }
}
export default FormConfig
