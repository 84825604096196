import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { HomeRoute, LoginRoute } from '../../Router/Routing';
import { black } from 'material-ui/styles/colors';
import AcessoNegadoImage from '../../img/AcessoNegadoImages/AcessoNegadoImage.png' 


const AcessoNegado = () => {
  const history = useHistory();

  const handleBack = () => {
    history.push(HomeRoute, {replace: true})
  };

  const handleLogin = () => {
    window.close()
    window.open(LoginRoute)
  };

  return (
    <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
      <img src={AcessoNegadoImage}></img>
      <Typography variant="h5" align="center">
        Seu usuário não tem permissão de acesso neste local.
      </Typography>
      <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', width: '100%', marginTop: '50px' }}>
        <Button variant="outlined" onClick={handleBack} style={{color: black, borderColor: black, width: '300px', fontSize: 18}}>
          Voltar
        </Button>
        <Button variant="outlined" onClick={handleLogin} style={{marginLeft: '10px', color: black, borderColor: black, width: '300px', fontSize: 18 }}>
          Fazer login com outro usuário
        </Button>
      </div>
    </Container>
  );
};

export default AcessoNegado;
