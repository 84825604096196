import React, { Component } from 'react'
import Form from '../../Components/Form/Form';
import { post, getByIdPessoaEndereco, getById, put } from '../../API/Enderecos';

import { camposForm as camposF } from './Config';
import { getUser } from '../../Router/auth';
import { SharedSnackbarConsumer } from '../../Context/SharedSnackbar.context';
import ButtonSubstituto from './ButtonSubstituto';
import Grid from '@material-ui/core/Grid';

class FormConfig extends Component {

    constructor(props) {
        super(props);

        let l = false;
        try {
            let id = this.props.match.params.id;

            if (id)
                l = true;
        } catch (e) { }

        this.state = { alertSucess: false, alertError: false, loading: l, loadingField: true, values: {}, camposForm: camposF };
    }

    create = async values => {
        values.endereco = {
            logradouro: values.logradouro,
            numero: values.numero,
            complemento: values.complemento,
            bairro: values.bairro,
            codigoMunicipio: values.codigoMunicipio,
            nomeMunicipio: values.nomeMunicipio,
            sigaUF: values.sigaUF,
            cep: values.cep,
            empresaId: values.empresaId,
            latitude: values.latitude,
            longitude: values.longitude
        };
        values.empresaId = getUser().empresaId;
        var query = new URLSearchParams(this.props.location.search);
        values.pessoaId = query.get('pessoaId');

        var retorno = await post(values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
            this.props.openSnackbar('Criado com sucesso!');

            if (this.props.returnId)
                this.props.returnId(retorno.data.id)
        } else {
            this.setState({ alertError: true });
            this.props.openSnackbar('Erro ao criar! Cadastro não criado.', 'error');
            throw true;
        }
    }
    update = async values => {
        values.endereco = {
            id: values.enderecoId,
            logradouro: values.logradouro,
            numero: values.numero,
            complemento: values.complemento,
            bairro: values.bairro,
            codigoMunicipio: values.codigoMunicipio,
            nomeMunicipio: values.nomeMunicipio,
            sigaUF: values.sigaUF,
            cep: values.cep,
            empresaId: values.empresaId,
            latitude: values.latitude,
            longitude: values.longitude
        };
        values.empresaId = getUser().empresaId;
        
        var id = this.props.match.params.id;
        
        var retorno = await put(id, values);
        if (retorno.result === 'ok') {
            this.setState({ alertSucess: true });
            this.props.openSnackbar('Atualizado com sucesso!');
        } else {
            this.setState({ alertError: true });
            this.props.openSnackbar('Erro ao atualizar! Cadastro não atualizado.', 'error');
            throw true;
        }
    }

    limpar = () => {
        this.setState({ alertSucess: false });
        this.setState({ alertError: false });
    }

    componentDidMount() {
        var ehSuporte = false;
        if (getUser() !== undefined && getUser().role === "RLuOKTh1Ko")
            ehSuporte = true;
        
        let id;
        try {
            id = this.props.match.params.id;
        } catch { }

        if (id) {
            getByIdPessoaEndereco(id).then(async (value) => {
                if (value !== undefined) {
                    var y = await getById(value.enderecoId);
                    if (y !== undefined) {
                        value = { ...y, ...value };
                        value.endereco = { id: value.enderecoId };
                    }
                    value.localizacao = { lat: value.latitude, lng: value.longitude };
                    if (value.enderecoFiscal)
                    {
                        this.state.camposForm.forEach(x => x.disabled = true);
                    }
                    if (ehSuporte)
                        this.state.camposForm.forEach(x => { if (x.name ==='latitude' || x.name ==='longitude') { x.disabled = false; } });
                    else 
                        this.state.camposForm.forEach(x => { if (x.name ==='latitude' || x.name ==='longitude') { x.disabled = true; } });
                    
                    if (!value.enderecoOrigemId)
                        this.state.camposForm= this.state.camposForm.filter(x=>x.name !== 'enderecoOrigemId')
                    this.setState({ values: value });
                }
                
                this.setState({ loading: false });
                this.setState({ loadingField: false });
            }).catch((e) => {
                throw new Error('Registro não encontrado!');
            });
        } else {
            this.state.camposForm.forEach(x => (x.name!=='enderecoOrigemId' && x.name!=='latitude' && x.name!=='longitude'? x.disabled = false: x.disabled = true));

            var query = new URLSearchParams(this.props.location.search);
            var enderecoOrigemId = query.get('enderecoOrigemId');
            if (!enderecoOrigemId)
                this.state.camposForm= this.state.camposForm.filter(x=>x.name !== 'enderecoOrigemId')

            this.state.values.tipo = 1;
            this.state.values.enderecoOrigemId = enderecoOrigemId;
            this.state.values.empresaId = getUser().empresaId;
            this.state.values.latitude = null;
            this.state.values.longitude = null;
            this.state.values.localizacao = { lat: -26.864373233253218, lng: -49.08949008129802 };

            this.setState({ values: this.state.values });
            this.setState({ loadingField: false });
        }

        
    }

    render() {
        var b = this.state.camposForm.find(e => e.name === "googleMaps");
        if (getUser() !== undefined && getUser().role === "RLuOKTh1Ko")
            b.component = <a title='Clique para abrir no Google Maps' onClick={() => { window.open('https://www.google.com.br/maps/place/' + this.state.values.latitude + "," + this.state.values.longitude, '_blank') }}>Abrir local no Google Maps</a>;

        let id;
        try {
            id = this.props.match.params.id;
        } catch { }
        if (id) {
            return (
                <div>
                    {(this.state.values.enderecoFiscal &&  this.state.values.enderecoFiscal ?

                        <Grid container spacing={2} style={{ marginTop: '5px', marginLeft: '11px', marginBottom: '8px' }} >
                            <Grid item xs={4} md={4}>
                                <ButtonSubstituto id={this.state.values.endereco.id} pessoaId={this.state.values.pessoaId}/>
                            </Grid></Grid>
                        : <></>
                    )}
                    <Form title="Editar endereço" form="enderecoEdit" CanNotSave={true} fields={this.state.camposForm} onSubmit={this.update} CanBack={true} initialValues={this.state.values} AlertSucess={this.state.alertSucess}
                        AlertError={this.state.alertError} loading={this.state.loading || this.state.loadingField} />

                </div>
            );
        } else {

            return (
                <Form title="Endereço" form="enderecoForm" CanNotSave={true} fields={this.state.camposForm} onSubmit={this.create} CanBack={true} initialValues={this.state.values} AlertSucess={this.state.alertSucess}
                    AlertError={this.state.alertError} loading={this.state.loadingField} >
                </Form>
            );
        }
    }
}

class FormSnackBar extends Component {
    render() {
        return (
            <SharedSnackbarConsumer>
                {({ openSnackbar }) => (
                    <FormConfig openSnackbar={openSnackbar} {...this.props} >
                    </FormConfig>
                )}
            </SharedSnackbarConsumer>
        );
    }
}

export default FormSnackBar;