import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import React, { Component, useEffect } from 'react';
import { Circle, useMapEvent } from 'react-leaflet';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Field, change, formValueSelector, reduxForm, submit } from 'redux-form';
import DateFields from '../Date/Date';
import DateTimeField from '../DateTime/DateTime';
import MapCircle from '../Map/MapCircle';
import Snackbar from '../Snackbar/Snackbar.js';
import LookupLocalizacao from '../BuscarLocalGoogle/LookupLocalizacao';
import PointLocalizacao from '../Map/PointLocalizacao';
import FormAutocompleteSelect from './LookupAssincrono';
import { InputAdornment } from '@mui/material';

const useStyles = (theme) => ({
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    title: {
        flex: '1 1 100%',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

const renderTextField = ({
    label,
    prefix,
    input,
    children,
    meta: { touched, invalid, error },
    ...custom
}) => (
    <TextField
        InputProps={{
            startAdornment: prefix ? (
                <InputAdornment
                    sx={{
                        margin: 0
                    }}
                    position="start">
                    {typeof prefix === 'function' ? prefix() : prefix}
                </InputAdornment>
            ) : null
        }}
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        variant="outlined"
        type="hidden"
        fullWidth
        {...input}
        {...custom}
    >
        {children}
    </TextField>
)

const renderTextFieldMoney = ({
    label,
    input,
    children,
    meta: { touched, invalid, error },
    ...custom
}) => (
    <CurrencyTextField
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        variant="outlined"
        currencySymbol="R$"
        outputFormat="string"
        decimalCharacter=","
        digitGroupSeparator="."
        type="hidden"
        fullWidth
        {...input}
        {...custom}
    >
        {children}
    </CurrencyTextField>
)

const renderTextFieldPeso = ({
    label,
    input,
    children,
    meta: { touched, invalid, error },
    ...custom
}) => (
    <CurrencyTextField
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        variant="outlined"

        currencySymbol="Kg"
        outputFormat="string"
        decimalCharacter=","
        minimumValue="0.00"
        decimalPlacesShownOnBlur={2}
        digitGroupSeparator="."


        type="hidden"
        fullWidth
        {...input}
        {...custom}

    >
        {children}
    </CurrencyTextField>
)

const renderTextFieldCubagem = ({
    label,
    input,
    children,
    meta: { touched, invalid, error },
    ...custom
}) => (
    <CurrencyTextField
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        variant="outlined"
        currencySymbol="m³"
        outputFormat="string"
        decimalCharacter=","
        minimumValue="0.00"
        decimalPlacesShownOnBlur={2}
        digitGroupSeparator="."
        type="hidden"
        fullWidth
        {...input}
        {...custom}
    >
        {children}
    </CurrencyTextField>
)

const required = value => (value == null ? 'Obrigatório' : undefined)
const requiredlookup_assincrono = value => (value === -1 || value === undefined ? 'Obrigatório' : undefined);

const grupo = grupo => {
    return <Grid item xs={12} sm={12}>
        <Typography variant="subtitle2" id="tableTitle" component="div">
            {grupo}
        </Typography>
    </Grid>
}

function MyComponent(props) {
    const map = useMapEvent({})

    useEffect(() => {
        map.setView(props.posi)
    }, [props.posi])

    return null
}

const blumenau = [-26.8549626, -49.165375];
const montaCampo = (campos, saveRef, change, initialValues, props, changePosi, changeField,
    latitude, longitude, raio,
    n_latitude, n_longitude, n_raio, form) => {

    const changePoint = (x) => {
        changePosi(x.lat, x.lng, 0);
    };

    const changeCircle = (x) => {
        if (x.type === "draw:edited" || x.type === "draw:editstop") {
            x.layers.eachLayer((l) => {
                changePosi(l.getLatLng().lat, l.getLatLng().lng, l.getRadius());
            });

        } else {
            if (!(n_latitude === x.layer.getLatLng().lat
                && n_longitude === x.layer.getLatLng().lng
                && n_raio === x.layer.getRadius())) {
                changePosi(x.layer.getLatLng().lat, x.layer.getLatLng().lng, x.layer.getRadius());
            }
        }
    };

    var component, options = [], select = false, op = [];

    if (campos.size === 0) {
        return <></>;
    }

    switch (campos.type) {
        case 'text':
            component = renderTextField;
            break;
        case 'bool':
            component = renderTextField;
            options = [{ value: true, label: 'Sim' }, { value: false, label: 'Não' }];
            select = true;
            break;
        case 'option':
            component = renderTextField;
            options = campos.options;
            select = true;
            break;
        case 'lookup':
            component = renderTextField;
            options = campos.options;
            select = true;
            break;
        case 'lookup_assincrono':
            return (
                <Grid item xs={12} sm={campos.size}>
                    <Field
                        name={campos.name}
                        component={FormAutocompleteSelect}
                        variant="outlined"
                        ref={saveRef}
                        label={campos.label}
                        onChang2={(value) => changeField(value, campos.onChange)}
                        validate={(campos.required ? requiredlookup_assincrono : null)}
                        required={campos.required}
                        disabled={campos.disabled}
                        {...campos}
                    />
                </Grid>
            )
        case 'lookup_localizacao':
            return (
                <Grid item xs={12} sm={campos.size}>
                    <Field
                        name={campos.name}
                        component={LookupLocalizacao}
                        variant="outlined"
                        ref={saveRef}
                        label={campos.label}
                        change={change}
                        formName={form}
                        disabled={campos.disabled}
                        {...campos}
                    />
                </Grid>
            )
        case 'ponto_localizacao':
            return (
                <Grid item xs={12} sm={campos.size}>
                    <Field
                        name={campos.name}
                        component={PointLocalizacao}
                        variant="outlined"
                        ref={saveRef}
                        label={campos.label}
                        change={change}
                        formName={form}
                        disabled={campos.disabled}
                        changePoint={changePoint}
                        latitude={latitude}
                        longitude={longitude}
                        {...campos}
                    />
                </Grid>
            )
        case 'date':
            campos.type = 'date'
            return <Grid item xs={12} sm={campos.size}>
                <Field
                    name={campos.name}
                    component={DateFields}
                    format={null}
                    variant="outlined"
                    ref={saveRef}
                    label={campos.label}
                    validate={(campos.required ? required : null)}
                    DateTimeFormat={Intl.DateTimeFormat}
                    required={campos.required}
                    disabled={campos.disabled}
                />
            </Grid>
        case 'datetime':
            campos.type = 'datetime'
            return <Grid item xs={12} sm={campos.size}>
                <Field
                    name={campos.name}
                    component={DateTimeField}
                    format={null}
                    variant="outlined"
                    ref={saveRef} label={campos.label} validate={(campos.required ? required : null)}
                    DateTimeFormat={Intl.DateTimeFormat}
                    required={campos.required}
                    {...campos}
                />
            </Grid>
        case 'time':
            component = renderTextField;
            return <Grid item xs={12} sm={campos.size}>
                <Field name={campos.name} component={component} validate={(campos.required ? required : null)}
                    label={campos.label} ref={saveRef} autoFocus={campos.autoFocus} select={select} {...campos} onChange={(value) => { changeField(value, campos.onChange) }} dataSource={op}>
                </Field>
            </Grid>
        case 'component':
            return <Grid item xs={12} sm={campos.size}>
                {campos.component}
            </Grid>

        case 'money':
            component = renderTextFieldMoney;
            break;
        case 'peso':
            component = renderTextFieldPeso;
            break;
        case 'cubagem':
            component = renderTextFieldCubagem;
            break;

        case 'mapCircle':
            var updateCircle = (latitude && longitude && n_latitude === undefined ? true : false);
            var posi = blumenau;
            var r = 500;
            if (updateCircle) {
                posi = [latitude, longitude];
                r = raio;
            }
            else if (n_latitude) {
                posi = [n_latitude, n_longitude];
                r = n_raio;
            }
            if (!r)
                r = 500;

            return (
                <Grid item xs={12} sm={campos.size}>
                    <MapCircle changeCircle={changeCircle} update={updateCircle} center={posi} >
                        <Circle key='key' center={posi} radius={r} />
                        <MyComponent posi={posi}></MyComponent>
                    </MapCircle>
                </Grid>
            );
        default:
            component = renderTextField;
            break;
    }

    return (
        <Grid item xs={12} sm={campos.size}>
            <Field name={campos.name} component={component} validate={(campos.required ? required : null)}
                label={campos.label} ref={saveRef} autoFocus={campos.autoFocus} select={select} {...campos} onChange={(value) => changeField(value, campos.onChange)} dataSource={op}>
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Field>
        </Grid>
    )
}

class UsuariosForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            n_latitude: undefined,
            n_longitude: undefined,
            n_raio: undefined,
            isNew: false,
            isBack: false,
            clicouEmSalvarEVoltar: false,
            clicouEmSalvarENovo: false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.AlertSucess !== this.props.AlertSucess) {
            if (this.state.clicouEmSalvarEVoltar) {
                this.props.dispatch(this.props.reset(this.props.form, {}));
                this.props.history.goBack();
                this.setState({ clicouEmSalvarEVoltar: false });
            }
            if (this.state.clicouEmSalvarENovo) {
                this.props.dispatch(this.props.reset(this.props.form, {}));

                this.props.history.replace(this.props.LinkNew, {});
                this.setState({ clicouEmSalvarENovo: false });
            }
        }
    }

    saveRef = ref => (this.ref = ref)

    changePosi = (latitude, longitude, raio) => {
        this.setState({
            n_latitude: latitude,
            n_longitude: longitude,
            n_raio: raio
        });

        this.props.change(this.props.form, 'n_raio', raio);
        this.props.change(this.props.form, 'n_longitude', longitude);
        this.props.change(this.props.form, 'n_latitude', latitude);
        this.props.change(this.props.form, 'longitude', longitude);
        this.props.change(this.props.form, 'latitude', latitude);
    }

    changeField = async (value, onChangeByCampo) => {
        if (typeof onChangeByCampo === 'function') {
            var retorno = await onChangeByCampo(value);
            if (retorno !== undefined) {
                await retorno.campos.forEach(element => {

                    this.props.change(this.props.form, element.campo, element.value);

                    if (element.lookup_parametro) {
                        var campoIndex = this.props.fields.findIndex((obj => obj.name === element.campo));
                        this.props.fields[campoIndex].lookup_parametro = element.lookup_parametro;
                    }
                });
            }
        }
    }

    render() {
        const { classes } = this.props;
        const { handleSubmit, pristine, numPizzas, reset, submitting, AlertSucess, AlertError, limpar, loading, change, initialValues, LinkNew, LinkEdit, CanBack, CanNotSave, CanOnlyBack, SalvarEVoltarLabel } = this.props

        return (
            <Container style={{ with: '100%' }} maxWidth='false'>
                <CssBaseline />
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    style={{ justifyContent: 'space-between' }}
                >
                    <div>
                        <Typography style={{ paddingTop: '10px', flex: '1 1 100%' }} variant="h5" component="div">
                            <b> {this.props.title}</b>
                        </Typography>
                    </div>
                    <div>
                        {this.props.actions}
                    </div>
                </Grid>
                <div className={classes.paper}>
                    <form className={classes.form} onSubmit={async (values) => {
                    }}>
                        <Grid container spacing={2}>
                            {this.props.fields.map(campo => {
                                if (campo.condicoesVisible) {
                                    var condicao = this.props.condicoesVisible[campo.condicoesVisible];
                                    if (condicao)
                                        return null;
                                }

                                if (campo.grupo)
                                    return [grupo(campo.grupo), montaCampo(campo, this.saveRef, change, initialValues, this.props, this.changePosi, this.changeField,
                                        this.props.latitude, this.props.longitude, this.props.raio,
                                        this.state.n_latitude, this.state.n_longitude, this.state.n_raio, this.props.form)]

                                return montaCampo(campo, this.saveRef, change, initialValues, this.props, this.changePosi, this.changeField,
                                    this.props.latitude, this.props.longitude, this.props.raio,
                                    this.state.n_latitude, this.state.n_longitude, this.state.n_raio, this.props.form);
                            })}
                        </Grid>

                        {CanOnlyBack === true ?
                            <Button variant="outlined" color="primary" style={{ marginLeft: '11px' }} className={classes.submit} onClick={async () => {
                                this.props.history.goBack();
                            }}>
                                Voltar
                            </Button> : <>

                                {CanNotSave === true ? <></> :
                                    <Button variant="contained" color="primary" className={classes.submit} onClick={async () => { await this.props.dispatch(submit(this.props.form)); }} >
                                        Salvar
                                    </Button>}
                                {LinkNew ?
                                    <Button variant="outlined" color="primary" style={{ marginLeft: '11px' }} className={classes.submit} onClick={async () => {
                                        await this.props.dispatch(submit(this.props.form)); this.setState({ clicouEmSalvarENovo: true });
                                    }}>
                                        Salvar e Novo
                                    </Button> : <></>}

                                {CanBack ?
                                    <Button variant="outlined" color="primary" style={{ marginLeft: '11px' }} className={classes.submit} onClick={async () => {
                                        await this.props.dispatch(submit(this.props.form));

                                        this.setState({ clicouEmSalvarEVoltar: true });
                                    }}>
                                        {SalvarEVoltarLabel ? SalvarEVoltarLabel : <>Salvar e Voltar</>}
                                    </Button> : <></>}
                            </>
                        }

                        <Backdrop className={classes.backdrop} open={submitting || loading} >
                            <CircularProgress />
                        </Backdrop>
                    </form>
                </div>
                <Snackbar open={AlertSucess} limpar={limpar} messagem="Salvo com sucesso!" severity="success" />
                <Snackbar open={AlertError} limpar={limpar} messagem="Erro ao salvar!" severity="error" />
            </Container>
        );
    }
}

const mapDispatchToProps = {
    change
}

const selector = (form, ...other) => (formValueSelector(form))(...other);

function mapStateToProps(state, ownProps) {
    var condicoesVisible = '';
    if (typeof ownProps.condicoesVisible == 'function') {
        condicoesVisible = ownProps.condicoesVisible(selector, state);
    }

    const { latitude, longitude, raio, new_latitude, new_longitude, new_raio } = selector(ownProps.form, state, 'latitude', 'longitude', 'raio', 'new_latitude', 'new_longitude', 'new_raio');
    return {
        initialValues: ownProps.initialValues,
        latitude,
        longitude,
        raio,
        new_latitude,
        new_longitude,
        new_raio,
        condicoesVisible
    }
}

UsuariosForm = connect(mapStateToProps, mapDispatchToProps)(UsuariosForm)

UsuariosForm = reduxForm({
    enableReinitialize: true,
})(UsuariosForm)

export default withRouter(withStyles(useStyles)(UsuariosForm))