const rota_data_inicio = "rota_data_inicio";
const rota_data_fim = "rota_data_fim";
const rota_regioes_selecionadas = "Rota_regioes_selecionadas";
const rota_motoristas_selecionadas = "Rota_motoristas_selecionadas";
const rota_analise_regioes_selecionadas = rota_regioes_selecionadas;
const rota_analise_veiculos_selecionados = "RotaAnalise_veiculos_selecionados";
const rota_analise_motoristas_selecionados = "RotaAnalise_motoristas_selecionados";

export {
    rota_data_inicio,
    rota_data_fim,
    rota_regioes_selecionadas,
    rota_analise_regioes_selecionadas,
    rota_analise_veiculos_selecionados,
    rota_analise_motoristas_selecionados,
    rota_motoristas_selecionadas
}