import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { vermelhoPastel } from '../../../Components/Map/MarkerColors';
import ErrorIcon from '@material-ui/icons/Error';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import AddIcon from '@material-ui/icons/Add';
import { getRotasByFiltro, transferirParada } from '../../../API/Rotas';
import { addEntregasInRota } from '../../../API/Entregas';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Box, Select } from '@material-ui/core';
import { getUser } from '../../../Router/auth';
import MultiplaSelecao from '../../../Components/MultiplaSelecao/MultiplaSelecao';
import { getFormatDataToDataApi } from '../../../Utils/FormatDateTime';
import MenuItem from '@mui/material/MenuItem';
import { AZUL_PASTEL } from '../../../Utils/ColorsGrafos';
import { rota_regioes_selecionadas } from '../../../Utils/StorageKeys';
import { useHistory } from "react-router-dom";
import { RotasNewRoute } from '../../../Router/Routing';

export function DialogSelectRota(props) {
	const { onClose, selectedValue, open, rotaId } = props;
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState('');
	const [dados, setDados] = React.useState([]);

	const [filtroRota, setFiltroRota] = React.useState({
		dataInicio: new Date(Date.now()).setHours(0, 0, 0),
		dataFim: new Date(Date.now()).setHours(23, 59, 59),
		empresaId: getUser().empresaId,
		regioesIds: [],
		status: [opcaoStausRota[0].value]
	});

	const handleDataInicio = (newDate) => {
		var date = new Date(newDate)
		if (!isNaN(date.getTime())) {
			setFiltroRota(prevState => ({
				...prevState,
				dataInicio: newDate
			}));
		}
	}

	const handleDataFim = (newDate) => {
		var date = new Date(newDate)
		if (!isNaN(date.getTime())) {
			setFiltroRota(prevState => ({
				...prevState,
				dataFim: newDate
			}));
		}
	}

	const handleRegiao = (newRegiaoIds) => {
		setFiltroRota(prevState => ({
			...prevState,
			regioesIds: newRegiaoIds
		}))
	}

	const handleChangeList = (event) => {
		const name = event.target.name;
		setFiltroRota(prevState => ({
			...prevState,
			[name]: [event.target.value],
		}));
	};

	const handleClose = () => {
		onClose(selectedValue);
	};

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);

				filtroRota.dataInicio = getFormatDataToDataApi(filtroRota.dataInicio);
				filtroRota.dataFim = getFormatDataToDataApi(filtroRota.dataFim);

				var rotas = await getRotasByFiltro(filtroRota);
				setDados(rotas);

				setLoading(false);
			} catch (error) {
				console.log("error", error);
			}
		};

		if (open)
			fetchData();
	}, [open, filtroRota]);

	const handleListItemClick = async (value) => {
		setLoading(true);
		var rotaId = value.id;

		try {
			var retorno = null;
			if (props.idsEntregas && props.idsParadas[0] === null) {
				// adiciona na rota quando não existe parada planejada
				retorno = await addEntregasInRota(rotaId, props.idsEntregas);
			}
			else {
				retorno = await transferirParada(rotaId, props.idsParadas, props.idsNotasFiscais);
			}

			if (!retorno) {
				throw new Error("Não foi possivel alterar a parada");
			}

			if (retorno.result === 'ok') {
				onClose();
				setLoading(false);
				props.openSnackbar('Parada alterada com sucesso!')
			}
			else {
				if (retorno.result === 'erro') {
					setError(retorno.data);
				} else {
					setError('Erro ao alterar a parada. Tente novamente!');
				}
			}
		} catch {
			setError('Erro ao alterar a parada. Tente novamente!');
		}
	};

	let history = useHistory();

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="lg"
		>
			<DialogTitle style={{ paddingBottom: "10px" }}>Selecione para qual rota as paradas devem ser transferidas</DialogTitle>
			<Box
				style={{
					height: "50px",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-around",
					margin: "0px 50px"
				}}>
				<KeyboardDatePicker
					disableToolbar
					autoOk
					style={{ width: '150px' }}
					variant="inline"
					format="dd/MM/yyyy"
					margin="normal"
					label="Data inicial"
					value={filtroRota.dataInicio}
					onChange={handleDataInicio}
				/>
				<KeyboardDatePicker
					disableToolbar
					autoOk
					style={{ width: '150px' }}
					variant="inline"
					format="dd/MM/yyyy"
					margin="normal"
					label="Data final"
					value={filtroRota.dataFim}
					onChange={handleDataFim}
				/>
				<Box style={{
					width: "350px"
				}}>
					<MultiplaSelecao
						label="Região"
						placeholder="Selecione a/as regiões desejadas"
						endpoint="api/RotaRegioes/lookupMultiplo"
						localStorageName={rota_regioes_selecionadas}
						onChange={handleRegiao}
					></MultiplaSelecao>
				</Box>
				<Select
					label="Situação da rota"
					onClick={handleChangeList}
					value={filtroRota.status}
					inputProps={{
						name: "status"
					}}
					style={{
						width: "200px"
					}}
				>
					{opcaoStausRota.map((opcao, index) => (
						<MenuItem key={index} value={opcao.value}>{opcao.label}</MenuItem>
					))}
				</Select>
			</Box>
			<Box
				style={{
					padding: "10px"
				}}>
				{
					error ?
						<div style={{ color: vermelhoPastel }}>
							<Typography variant="h6" align="center" style={{ padding: "30px 0px" }}><ErrorIcon style={{ marginLeft: '20px', marginRight: '10px' }}></ErrorIcon>{error}</Typography>
						</div>
						:
						loading ?
							<div style={{
								height: "300px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}>
								<CircularProgress />
							</div>
							:
							<List>
								<ListItem autoFocus button onClick={() => { history.push(RotasNewRoute + "?paradasIdsTransferir=" + props.idsParadas) }}>
									<ListItemAvatar>
									<Avatar style={{background: '#6f6c6c'}}>
										<AddIcon  />
									</Avatar>
									</ListItemAvatar>
									<ListItemText primary="Cadastrar uma nova rota" />
								</ListItem>
								
								{dados.map((rota) => (
									<ListItem button onClick={() => handleListItemClick(rota)} key={rota.id}>
										<ListItemAvatar>
											<Avatar style={{
												backgroundColor: rota.status === statusRotaEnum.Emrota ? AZUL_PASTEL : undefined
											}}>
												<CallSplitIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={`${rota.rotaRegiaoDescricao ? `${rota.rotaRegiaoDescricao} -` : ''} ${opcaoStausRota.find(opcao => opcao.value === rota.status).label}`}
											secondary={`${rota.descricao} - ${rota.motoristaNome}`} />
									</ListItem>
								))}

								{dados.length === 0 && (
									<Typography variant="h6" align="center" style={{ padding: "30px 0px" }}>Nenhuma rota encontrada</Typography>
								)}
							</List>
				}
			</Box>
		</Dialog >
	);
}


const opcaoStausRota = [
	{ value: 1, label: 'Aguardando início' },
	{ value: 2, label: 'Em rota' }
];

const statusRotaEnum = {
	AguardandoInicio: 1,
	Emrota: 2
}