import { Grid, TextField } from "@mui/material"
import { fields } from "./Config"
import { useContext, useEffect, useState } from "react"
import { AjusteEnderecoContext } from "../../../../Context/AjusteEnderecoContext"
import { getByEnderecoId } from "../../../../API/Pessoas"

export const EnderecoForm = () => {
    const { enderecoAjuste, coords, setCoords, textCoords, setTextCoords } = useContext(AjusteEnderecoContext);
    const [destinatario, setDestinatario] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getByEnderecoId(enderecoAjuste.id, enderecoAjuste.empresaId);
                if (response != null) {
                    setDestinatario(response);
                }
            } catch {
                setDestinatario(null);
            }
        }

        if (enderecoAjuste != null) {
            var lat = enderecoAjuste.latitude ?? 0;
            var lng = enderecoAjuste.longitude ?? 0;

            setCoords({
                lat: lat,
                lng: lng,
                erro: false,
            });

            setTextCoords(`${lat}, ${lng}`);
        }

        fetchData();
    }, [enderecoAjuste, setCoords, setTextCoords]);

    const getField = (item, field) => {
        if (!item)
            return "";

        var campos = field.split('.');
        for (let index = 0; index < campos.length; index++) {
            const element = campos[index];
            item = item[element];
        }

        if (item == null)
            item = ""

        return item;
    }

    const handleChangeCoords = (e) => {
        const re = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
        var text = e.target.value;

        var latlng = text.split(",")

        var lat = !isNaN(Number(latlng[0])) ? latlng[0] : 0;
        var lng = !isNaN(Number(latlng[1])) ? latlng[1] : 0;

        setCoords({
            lat: Number(lat),
            lng: Number(lng),
            error: !re.exec(text)
        });

        setTextCoords(text);
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
                height: "100%"
            }}
        >
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
            >
                <TextField
                    fullWidth
                    label="Destinatário"
                    value={destinatario ? destinatario.razaoSocial : ""}
                    disabled
                />
            </Grid>

            {fields.map((item, index) => {
                let field = "";

                if (enderecoAjuste != null) {
                    field = getField(enderecoAjuste, item.field);
                }

                return (
                    <Grid
                        item
                        key={index}
                        xs={item.xs}
                        sm={item.sm}
                        md={item.md}
                        lg={item.lg}
                        xl={item.xl}
                    >
                        <TextField
                            fullWidth
                            label={item.label}
                            value={field}
                            disabled={item.disabled}
                            error={item.error}
                            onChange={(e) => item.onChange(e, item)}
                        />
                    </Grid>
                )
            })}

            <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
            >
                <TextField
                    fullWidth
                    label={"Latitude, Longitude"}
                    value={textCoords}
                    error={coords.error}
                    onChange={handleChangeCoords}
                />
            </Grid>
        </Grid>
    )
}